import React from 'react'
import { Link } from 'react-router-dom'

import { TextContainer, MailBtn } from '../../containers/case-study/casestudy.style'

const Email = ({ text1, text2 }) => {
  return (
    <TextContainer>
          <h2 style={{ fontSize: 'clamp(1.8rem, 0.74rem + 4.24vw, 3.125rem)', fontWeight: '500' }}>
            {
                text1.map((item, id) => {
                  return <>
                        {item} <br/>
                    </>
                })
            }
          </h2>

          <h1 style={{ fontSize: 'clamp(2.1rem, 0.78rem + 5.28vw, 3.75rem)', fontWeight: '500' }}>
             {
                text2.map((item, id) => {
                  return <>
                        {item} <br/>
                    </>
                })
            }
          </h1>
          <div>
            <Link to='#'>
             <MailBtn onClick={(e) => {
               window.location.href = 'mailto:info@socialambience.com'
               e.preventDefault()
             }}>
              Email address : info@socialambience.com
            </MailBtn>
            </Link>
          </div>
        </TextContainer>
  )
}

export default Email
