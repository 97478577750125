import React from 'react'

import { StyledButton, Span, Inner } from './Button.styled'

// eslint-disable-next-line react/prop-types
const Button = ({ text, mode, padding, clickEvent }) => {
  return (
    <>
        <StyledButton mode = {mode} padding={padding} onClick={clickEvent}>
          <Inner>
            <Span mode = {mode}>{text}</Span>
          </Inner>

        </StyledButton>
    </>
  )
}

export default Button
