import styled from 'styled-components'
import { DarkGradientCSS, LightGradientCSS } from '../../assets/styles/Global.styled'

export const Span = styled.h4`
    font-size: ${(props) => props.mode === 'smalldark' ? 'clamp(1rem, -1.5rem + 8vw, 1.8rem)' : (props.mode === 'dark') ? 'clamp(1rem, -1.5rem + 8vw, 3rem)' : 'clamp(1rem, -1.5rem + 8vw, 2.2rem)'};
    ${(props) => props.mode === 'dark' || props.mode === 'smalldark' ? LightGradientCSS : DarkGradientCSS};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* -webkit-text-stroke: 2px transparent; */
    font-weight: 900;
    filter: ${(props) => (props.mode === 'dark' || props.mode === 'smalldark') && 'drop-shadow(0px 6px 4px rgba(49,42,49,0.6))'};
`
export const StyledButton = styled.button`
    border: none;
    cursor: pointer;
    border-radius : 12px;
    box-shadow:${(props) => props.mode === 'dark' ? '0 8px 6px -6px rgba(233,139,211,0.85)' : props.mode === 'smalldark' ? ' 10px 10px 56px -17px rgba(0,0,0,0.75)' : '0 8px 6px -6px #D4CDFE'};
    transition: color 1s ease-in-out;
    border: double 2px transparent;
    background-image:${(props) => props.mode === 'dark' || props.mode === 'smalldark' ? 'linear-gradient(90deg, rgba(189, 76, 184, 1) 0%, rgba(130, 31, 128, 1) 100%),linear-gradient(90deg, rgba(130,31,128,1) 1%, rgba(186,76,181,1) 100%)' : 'linear-gradient(90deg, rgba(231, 230, 243, 1) 0%, rgba(212, 204, 255, 1) 100%), linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, #c5bcf5 100%)'};
    background-origin: border-box;
    background-clip: content-box,  border-box;

    &:hover{
        background-image:${(props) => props.mode === 'light' ? 'linear-gradient(90deg, rgba(189, 76, 184, 1) 0%, rgba(130, 31, 128, 1) 100%),linear-gradient(90deg, rgba(130,31,128,1) 1%, rgba(186,76,181,1) 100%)' : 'linear-gradient(90deg, rgba(231, 230, 243, 1) 0%, rgba(212, 204, 255, 1) 100%), linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, #c5bcf5 100%)'};
        box-shadow:${(props) => props.mode === 'light' ? '0 8px 6px -6px rgba(233,139,211,0.85)' : '0 8px 6px -6px #D4CDFE'};
 
    }

    &:hover ${Span} {
        ${(props) => props.mode === 'dark' || props.mode === 'smalldark' ? DarkGradientCSS : LightGradientCSS};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        filter: ${(props) => (props.mode === 'light') ? 'drop-shadow(0px 6px 4px rgba(49,42,49,0.6))' : 'none'};
    }
`
export const Inner = styled.div`
    padding:15px 30px;
`
