import React from 'react'
import { Row } from '../../containers/case-study/casestudy.style'
import { GradientBox, Date } from '../Chart/chart.styled'

import Calendar from '../../assets/svgs/calendar.svg'

const DateText = ({ date, compare, content, width, color, font }) => {
  return (
    <Row content={content} style ={{ gap: '10px', alignItems: 'baseline' }}>
        <GradientBox width = {width || '199px'} height = '24px' date={true}
            style={{
              borderRadius: '5px',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '3px',
              marginTop: '8px',
              boxShadow: 'none'
            }}>
            <img src={Calendar} style={{ width: '11px', height: '11px' }}/>
            <Date>{date}</Date>
        </GradientBox>
        <div>
            <Date font={font} style={{ color: color || '#fff', fontWeight: '400' }}>{compare} </Date>
        </div>
    </Row>
  )
}

export default DateText
