import React from 'react'

import CASE from '../../constants/Case'

import { MainContainer } from '../About/about.style'
import { InnerContainer } from './casestudy.style'

import Heading from '../../components/Heading/Heading'
import Case from '../../components/Case/Case'

const CaseStudies = () => {
  return (
    <MainContainer>
     <Heading title='Case Study'/>
     <InnerContainer>
      {
         [...new Map(CASE.map(item => [item.id, item])).values()].map((item, index) => <Case key ={index} detail={item}></Case>)
      }
     </InnerContainer>
    </MainContainer>
  )
}

export default CaseStudies
