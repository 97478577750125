import React from 'react'
import { Container, Detail, ImageContainer } from './LogoText.styled'
import { Title } from '../../containers/Home/Home.styled'

const LogoText = ({ data }) => {
  const { logo, title, details } = data
  return (
    <Container>
        <ImageContainer>
            <img src={require(`../../assets/svgs/confident/${logo}`)} alt="logo" style={{ maxWidth: '60px', maxHeight: '78px' }}/>
        </ImageContainer>
        {
            title.map((data, id) => {
              return (
                <Title key={id} bold={true} fontmax= '1.5rem' fontmin='1rem' style={{ textTransform: 'uppercase', lineHeight: '1.5rem' }}>
                    {data}
                </Title>
              )
            })
        }
        <Detail>
            {details}
        </Detail>
    </Container>
  )
}

export default LogoText
