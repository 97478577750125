import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    min-height: 100px;
    border-radius: 17px;
    transition: all 0.3s ease-out;
    background:linear-gradient(90deg, rgba(231, 230, 243, 1) -0%, rgba(212, 204, 255, 1) 100%);
    display: flex;
    justify-content: space-between;
    box-shadow: 1px 13px 12px 2px rgba(197,188,245,0.75);

    background-image: linear-gradient(90deg, rgba(231, 230, 243, 1) 0%, rgba(212, 204, 255, 1) 100%), linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, #c5bcf5 100%);
    background-origin: border-box;
    background-clip: content-box,  border-box;

    margin-bottom: 50px;

    @media (max-width: 960px) {
       flex-direction: column;
       gap: 1rem;
    }
`
export const InnerFlex = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5px 50px 0 50px;
    align-items: center;
`
