import styled from 'styled-components'
import { FlexBox, LightGradientCSS } from '../../assets/styles/Global.styled'

export const ImageContainer = styled.div`
    width: 100%;
    height: 100%;
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    ${FlexBox}
`

export const RatioContainer = styled.div`
  ${FlexBox}
  ${LightGradientCSS}
  border-radius : ${(props) => props.radius};
  padding-top: 62.5%;
  position: relative;
  width: 100%;
`
export const Image = styled.img`
    width: ${(props) => props.width};
    height: auto;
`
