import styled from 'styled-components'
import { FlexBox } from '../../assets/styles/Global.styled'

export const Wrapper = styled.div`
  ${FlexBox}
  flex-direction: column;
  width: 100%;
  height: 420px;
   @media (max-width: 1225px) {
    height: 500px;
  }
   @media (max-width: 1005px) {
    height: 600px;
  }
  @media (max-width: 530px) {
    height: 500px;
  }
  @media (max-width: 440px) {
    height: 580px;
  }
  @media (max-width: 315px) {
    height: 650px;
  }

`
export const Container = styled.div`
  position: relative;
  max-width: 760px;
  width: 55%;
  min-height: 100%;
  perspective: 500px;
  transform-style: preserve-3d;
  @media (max-width: 540px) {
    width: 90%;
  }
`

export const CardContainer = styled.div`
  position: absolute;
  width: 100%;
  min-height: 23rem;

  transform: rotateY(calc(var(--offset) * 50deg))
    scaleY(calc(1 + var(--abs-offset) * -0.4))
    translateZ(calc(var(--abs-offset) * -30rem))
    translateX(calc(var(--direction) * -5rem));
  transition: all 0.3s ease-out;

  @media (max-width: 540px) {
    display: ${(props) => (props.hide ? 'block' : 'none')} !important;
  }
`

export const NavContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin: 50px 0 40px 0;
  gap: 0.5rem;
`
export const Circle = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50px;
  border: 1px solid #fff;
  cursor: pointer;
`

export const Btn = styled.button`
  color: white;
  font-size: 3rem !important;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 45%;
  z-index: 2;
  cursor: pointer;
  user-select: none;
  background: unset;
  border: unset;
  background-color: #000;
  border-radius: 50%;
  opacity: 0.3;
  display: none;

  @media (max-width: 630px) {
    display: flex;
  }
`
export const LeftBtn = styled(Btn)`
  position: absolute;
  left: 10%;
  transform: translateX(-100%) translatey(-50%);
`
export const RightBtn = styled(Btn)`
  right: 10%;
  transform: translateX(100%) translatey(-50%);
`
