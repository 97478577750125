import React from 'react'
import CONFIDENT from '../../../constants/Confident'

import { Container, Title, Child } from '../Home.styled'

import LogoText from '../../../components/LogoText/LogoText'

const Confident = () => {
  return (
     <Container heightXL = '950px' heightXS='1300px' style={{ paddingTop: '50px' }}>
        <Title fontmax= '2.3rem' fontmin='1.2rem' style={{ fontWeight: '500' }}>
        We Transcend <br />
        Your Current Ad Results
        </Title>

        <Title fontmax= '4.7rem' fontmin='1.7rem' bold={true} style={{ textTransform: 'uppercase' }}>
        Or You Get Your  <br />
        Money Back
        </Title>

        <Title fontmax= '2.8rem' fontmin='1.3rem' style={{ fontWeight: '500' }}>
        How are we so confident?
        </Title>

        <Child style={{ width: '100%', flexWrap: 'wrap' }}>
            {
            CONFIDENT.map((data, id) => {
              return (
                <LogoText key={id} data = {data}/>
              )
            })
        }
        </Child>

    </Container>
  )
}

export default Confident
