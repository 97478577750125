import React from 'react'
import { Link } from 'react-router-dom'
import { NavItem } from './Navbar.style'

const Links = ({ textColor, caseRef }) => {
  const links = [
    {
      label: 'Case Studies',
      path: '/case'
    },
    {
      label: 'About Us',
      path: '/about'
    },
    {
      label: 'Careers',
      path: '/careers'
    }
  ]
  return (
    <>
      {
         links.map((link, id) => {
           return (
              <>
                <Link key={id} to={link.path}>
                    <NavItem textColor= {textColor}>
                        {link.label}
                    </NavItem>
                </Link>
              </>

           )
         })
        }
    </>
  )
}

export default Links
