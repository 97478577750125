import React from 'react'

// styles
import { Person, ReviewBox, Comment, StyledRating, Message, ImageContainer, TopLeft, Name, Description } from './Card.styled'

// images
import Apostrophe from '../../assets/svgs/apostrophe.svg'

const ReviewCard = ({ details }) => {
  const { icon, name, position, star, message } = details
  return (
    <ReviewBox>
        <Person>
            <ImageContainer>
                <img src = {require(`../../assets/images/${icon}`)} alt="reviewer"/>
                <TopLeft>
                    <img src ={Apostrophe} alt="'"/>
                </TopLeft>
            </ImageContainer>
            <Name>{name}</Name>
            <Description>{position}</Description>
        </Person>
        <Comment>
            <StyledRating name="customized-color" value={star} precision={0.5} readOnly />
            <Message style={{ display: 'block' }}>
                {message}
            </Message>
        </Comment>

    </ReviewBox>
  )
}

export default ReviewCard
