import React from 'react'

import { Row, AbsoluteTile, RowWrapper, TableWrapper } from '../../containers/case-study/casestudy.style'

const Table = ({ text, width, height, img, top, wrapperWidth }) => {
  return (
    <RowWrapper>
    <Row>
        <TableWrapper wrapperWidth={wrapperWidth} style={{ display: 'flex', justifyContent: 'center', maxWidth: { width }, height: { height }, position: 'relative', marginTop: '20px' }}>
            <img src={require(`../../assets/svgs/timeline/${img}`)} alt="table" style={{ width: '100%' }}/>
            <AbsoluteTile dark={true} style ={{ width: '100%', textAlign: 'left', position: 'absolute', left: '15px' }} top={top}>
            {text}
            </AbsoluteTile>
        </TableWrapper>
    </Row>
    </RowWrapper>
  )
}

export default Table
