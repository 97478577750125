import styled from 'styled-components'
import { DarkGradientCSS } from '../../assets/styles/Global.styled'
import '../../assets/fonts/index.css'
import '../../assets/fonts/MYRIADPRO-SEMIBOLD.woff'

export const Container = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.colors.primary.purple};
    position: relative;
    min-height:850px;
    z-index:2;
    &::before{
        content:"";
        bottom:0px;
        background:url('/bgcurve.svg');
        position:absolute;
        right:0px;
        z-index:-1;
        height:600px;
        width:100%;
    }
         @media (max-width: 992px) {
            min-height:500px;
         &::before{
            display:none;
         }
        }
    
`

export const Overlay = styled.div`
    background-color: transparent;
    display: flex;
    justify-content:center;
    align-items:center;
    width: 80%;
    height:100%;
    padding-bottom: 12%;
    z-index :0;
    margin:auto;
    padding-top:50px;
    @media (max-width: 1200px) {
        padding-bottom: 20%;
    }
     @media (max-width: 992px) {
        flex-direction:column;
        padding-top:50px;
        padding-bottom:0px;
    }

    @media (max-width: 680px) {
        width : 96%;
        margin-left: 2%;
        padding-bottom: 25%;
    }
 
`

export const BannerLeft = styled.div`
    width:40%;
     @media (max-width: 992px) {
        width:100%;
        text-align:center;
      
    }
`
export const BannerRight = styled.div`
    width:60%;
    position:relative;
    @media (max-width: 992px) {
        width: 100%;
        padding:10px 0px;
    }
  
`
export const GradientChart = styled.div`
    width : ${(props) => props.width};
    height :${(props) => props.height};
    border-radius: 25px;
    position: absolute;
    /* border: 1px solid #fff; */
    display: flex;
    flex-direction: column;
    border: double 2px transparent;
    background-image: linear-gradient(90deg, rgba(231, 230, 243, 1) 0%, rgba(212, 204, 255, 1) 100%), linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, #c5bcf5 100%);
    background-origin: border-box;
    background-clip: content-box,  border-box;
    box-shadow: -8px 7px 20px -7px #5c2f5c;

    @media (max-width: 680px) {
        width : 40%;
        height: auto;
    }

    @media (max-width: 520px) {
        width : 80%;
        margin-bottom: 5%;
    }
`

export const InnerContainer = styled.div`
    width : 100%;
    position:relative;
    padding : 20px;
    display: flex;
    flex-direction: column;
    gap: 6px;
`
export const Item = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`

export const Text = styled.p`
    font-size : ${(props) => props.font};
    font-weight : ${(props) => props.bold ? 'bolder' : 'lighter'};
    color : ${(props) => props.color ? props.color : '#000'};
`
export const Absolute = styled.div`
    position: absolute;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    left : ${(props) => props.left};
    top : ${(props) => props.top};
    bottom : ${(props) => props.bottom};
    right : ${(props) => props.right};


`

export const GradientCurve = styled.div`
    position: absolute;
    left: 0;
    width: 100%;
    bottom: -6px;
`
export const TextBox = styled.div`
    width: 100vw;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: clamp(100px,15vh,200px);
    margin-bottom: 20px;
    text-align: center;
    @media (max-width: 992px) {
        height: 4px;
        bottom: -10%;
    }

    @media (max-width: 530px) {
        width: 60%;
        margin-left: 22%;
        padding-top: 120px;
        bottom: -15%;
    }

     @media (max-width: 230px) {
        bottom: -20%;
    }
`
export const Span = styled.span`
    font-family : 'Raleway', sans-serif;
    font-weight: ${(props) => props.fontweight};
    font-size: clamp(0.8rem, 1.8vw, 2rem);
    color: ${(props) => props.theme.colors.primary.purple};
`

export const Slogan = styled.h1`
    font-size: clamp(1.35rem, 0.85rem + 1.6vw, 1.75rem);
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${(props) => props.theme.text.light};
`

export const ProgressBar = styled.div`
    width : ${(props) => props.width};
    height : 11px;
    border-radius : 20px;
    border: double 1px transparent;
    background-image: linear-gradient(90deg, rgba(231, 230, 243, 1) 0%, rgba(212, 204, 255, 1) 100%), linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, #c5bcf5 100%);
    background-origin: border-box;
    background-clip: content-box,  border-box;
    position: relative;
    z-index: 1000;
    box-shadow: 0 6px 3px -3px #c5bcf5;
`

export const FloatingPlayer = styled.div`
    width : 150px;
    min-height: 150px;
    position: absolute;
    bottom : -20%;
    right: -28%;
    border-radius: 13px;
    padding: 20px;
    z-index : 101;
    box-shadow: -8px 7px 20px -7px #6e466e;
    ${DarkGradientCSS}

    @media (max-width: 680px) {
        display: none;
    }
`
export const GraphContainer = styled(Absolute)`
    z-index : -1;
    background-color: rgba(130, 32, 127, 1);
    border-radius: 20px;
    border: double 1px transparent;
    background-image: linear-gradient(90deg, rgba(130, 32, 127, 1) 0%, rgba(130, 32, 127, 1) 100%), linear-gradient(90deg, rgba(189, 76, 184, 1) 0%, rgba(130, 31, 128, 1) 100%);
    background-origin: border-box;
    background-clip: content-box,  border-box;
`
