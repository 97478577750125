import styled from 'styled-components'

export const Container = styled.div`
    width: 32%;
    display: flex;
    flex-direction: column;
    padding: 0 70px;
    margin-top: 20px;
    @media (max-width: 1080px) {
         padding: 0 40px;
    }
    @media (max-width: 992px) {
         padding: 0 20px;
    }
    @media (max-width: 830px) {
        width: 80%;
        flex-direction: column;
    }

`
export const Detail = styled.p`
    font-size: 0.9rem;
    line-height : 21px;
    color: ${(props) => props.theme.text.light};
    margin-top: 20px;
    font-weight: 400;
    text-align:justify;
    word-spacing:-2px;
    width:100%;
    text-align-last: center;
`

export const ImageContainer = styled.div`
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
`
