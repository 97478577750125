import React from 'react'

import OPENPOSITION from '../../constants/OpenPosition'

import Heading from '../../components/Heading/Heading'
import Position from '../../components/Position/Position'

import { MainContainer } from '../About/about.style'
import { InnerContainer, Title } from './Careers.style'

const Careers = () => {
  return (
    <MainContainer>
         <Heading title='Careers'/>
         <InnerContainer>
            <Title style={{ textAlign: 'center', padding: '20px 0' }}>
                Open Positions
            </Title>
            {
                OPENPOSITION.map((item, index) => <Position key ='index' detail = {item}/>)
            }
         </InnerContainer>
    </MainContainer>
  )
}

export default Careers
