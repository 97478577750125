import styled, { css } from 'styled-components'
import { DarkGradientCSS } from '../../assets/styles/Global.styled'

export const Nav = styled.div`
    width: 100vw;
    min-height: 73.9px;
    height: 8vh;
    font-weight : 500;
    font-size : 1.125rem;
    color : ${props => props.theme.colors.textLight};
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${DarkGradientCSS}
`

const commonCssFlex = css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`
export const Navigation = styled.div`
    ${commonCssFlex}
    width: 60%;
    gap: 1.5rem;
    @media (max-width: 830px) {
        display: none;
    }
`

export const LogoContainer = styled.div`
    ${commonCssFlex}
    width: 40%;
`

export const ImgLogo = styled.img`
    width : 162px;
    height: 52px;

    @media (max-width: 510px) {
        width: 100px;
        height: 48px;
        margin-left : 5px;
    }
`

export const NavItem = styled.div`
    color : ${props => props.textColor === 'light' ? props.theme.text.light : props.theme.colors.primary.purple};
    position: relative;
    cursor : pointer;
    font-size: 1.21rem;

    &:after {
        content: "";
        display: block;
        margin: auto;
        height: 2px;
        width: 0;
        top: 5px;
        background: transparent;
        transition: all 0.3s;
        border-radius: 10px;
    }
    @media (min-width: 1580px) {
        font-size: 1.4rem;
    }

    &:hover::after,
    &:focus::after {
        background: ${props => props.textColor === 'light' ? props.theme.text.light : props.theme.colors.primary.purple};
        width: 99%;
    }
`

export const SideNav = styled.div`
    display: none;

    @media (max-width: 830px) {
        display: flex;
    }
`
export const Icon = styled.div`
    cursor: pointer;
        transition: 0.3s;
`
export const SideMenu = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    width: ${(props) => props.toggle ? '60%' : 0};
    height: 100vh;
    padding: ${(props) => props.toggle ? '20px 40px' : 0};
    display: flex;
    flex-direction: column;
    background-color: #fff;
    gap: 1.4rem;
    font-size: clamp(1rem, 0.4878rem + 1.9512vw, 1.5rem);
    overflow-x: hidden;
    transition: 0.3s;
`
