import styled, { createGlobalStyle, css } from 'styled-components'
import '../fonts/index.css'
import '../fonts/MyriadPro-Light.woff'
import '../fonts/Gilroy-ExtraBold.ttf'

const GlobalStyles = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  body{
    /* font-family: 'Myriad Pro Light'; */
    font-family : 'Raleway', sans-serif;
    font-weight: 400;
    overflow-y:scroll;
    overflow-x:hidden;
    background-color: #fff;
    font-weight: 600;
  }

  a{
    text-decoration: none;
  }

  ul{ 
    list-style-type: none;
  } 

`

export const DarkGradientCSS = css`
  background : -moz-linear-gradient(0% 50% 0deg,rgba(189, 76, 184, 1) 0%,rgba(130, 31, 128, 1) 100%);
  background : -webkit-linear-gradient(0deg, rgba(189, 76, 184, 1) 0%, rgba(130, 31, 128, 1) 100%);
  background : -webkit-gradient(linear,0% 50% ,100% 50% ,color-stop(0,rgba(189, 76, 184, 1) ),color-stop(1,rgba(130, 31, 128, 1) ));
  background : -o-linear-gradient(0deg, rgba(189, 76, 184, 1) 0%, rgba(130, 31, 128, 1) 100%);
  background : -ms-linear-gradient(0deg, rgba(189, 76, 184, 1) 0%, rgba(130, 31, 128, 1) 100%);
  background : linear-gradient(90deg, rgba(189, 76, 184, 1) 0%, rgba(130, 31, 128, 1) 100%);
`
export const LightGradientCSS = css`
  background : -moz-linear-gradient(8.79% 50% 0deg,rgba(231, 230, 243, 1) 0%,rgba(212, 204, 255, 1) 100%);
  background : -webkit-linear-gradient(0deg, rgba(231, 230, 243, 1) 0%, rgba(212, 204, 255, 1) 100%);
  background : -webkit-gradient(linear,8.79% 50% ,98.63% 50% ,color-stop(0,rgba(231, 230, 243, 1) ),color-stop(1,rgba(212, 204, 255, 1) ));
  background : -o-linear-gradient(0deg, rgba(231, 230, 243, 1) 0%, rgba(212, 204, 255, 1) 100%);
  background : -ms-linear-gradient(0deg, rgba(231, 230, 243, 1) 0%, rgba(212, 204, 255, 1) 100%);
  background : linear-gradient(90deg, rgba(231, 230, 243, 1) 0%, rgba(212, 204, 255, 1) 100%);
`

export const FlexBox = css`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const MarginContainer = styled.div`
  width: 65%;
  margin-left: 17.5%;
  ${FlexBox}
  flex-direction: column;
  gap: 2rem;
  margin-top: 2.5rem;

  @media (max-width: 530px) {
    width: 90%;
    margin-left: 5%;
    margin-top: 7rem;
  }

  @media (max-width: 290px) {
    margin-top: 10rem;
  }
`

export const GradientContainer = styled.div`
  width: ${(props) => props.width};
  height:${(props) => props.height};
  ${FlexBox}
  ${LightGradientCSS}
`
export default GlobalStyles
