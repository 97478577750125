import React from 'react'

import { Container, InnerFlex } from './Position.style'

import Button from '../Button/Button'
const Position = ({ detail }) => {
  function handleClick() {
    window.open("https://docs.google.com/spreadsheets/d/1AvgJeOatCGGbQgdPuQBueEPNKQapXqNHxzfh9rB-ROs/edit#gid=0")
  }

  return (
    <Container>
        <InnerFlex>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <span style={{ fontWeight: 'bold' }}>{detail.position}</span>
                <span > {detail.company}</span>
                <span style={{ fontStyle: 'italic' }} >{detail.location}</span>
            </div>
            <div style={{ marginTop: '40px', paddingBottom: '50px' }}>
                <Button
                text="Apply Now"
                mode="smalldark"
                clickEvent={()=>handleClick()}
                />
            </div>
        </InnerFlex>
    </Container>
  )
}

export default Position
