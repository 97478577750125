
import styled from 'styled-components'

export const GradientBox = styled.div`
    width : ${(props) => props.width};
    height :${(props) => props.height};
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    border: double 2px transparent;
    background-image: linear-gradient(90deg, rgba(231, 230, 243, 1) 0%, rgba(212, 204, 255, 1) 100%), linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, #c5bcf5 100%);
    background-origin: border-box;
    background-clip: content-box,  border-box;
    box-shadow: -8px 7px 20px -7px #5c2f5c;
    margin-top: 20px;

    @media (min-width: 1600px) {
          width : ${(props) => props.minWidth};
          height: ${(props) => props.minHeight && (props.minHeight !== '600px' && props.minHeight !== '300px' && props.minHeight !== '520px') ? '400px' : props.minHeight};
    }
    @media (min-width: 1900px) {
          height: ${(props) => props.minHeight ? props.minHeight : 'auto'};
    }
    @media (max-width: 450px) {
          width: ${(props) => !props.date && '88%'};
          margin-left:  ${(props) => !props.date && '5%'};
    }
`

export const InnerContainer = styled.div`
    width : 100%;
    padding : 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height : 100%;
`
export const Row = styled.div`
    display : flex;
    /* align-items: baseline; */
    font-size : 9px;
    line-height : 0.6rem;
    color : #232323;
    justify-content: space-between;
    font-family: 'Myriad Pro Regular' ;
`
export const Name = styled.div`
    width : 60%;
`

export const Price = styled.div`
    width : 20%;
`

export const Percent = styled.div`
    width : 20%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
export const Date = styled.span`
    font-size: 9px;
    color : #232323;
    font-family: 'Myriad Pro Regular' ;

    @media (min-width : 1650px) {
        font-size: ${(props) => props.font || '0.7075rem'};
    }
`
