
import React from 'react'
import { Link } from 'react-router-dom'
import { Title, LinkText, LogoContainer, MiniTitle, BottomTitle } from '../Card/Card.styled'

import { Container, MainContainer } from './case.style'
const Case = ({ detail }) => {
  return (
    <MainContainer>
        {console.log(detail)}
        <Container>
            <LogoContainer style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={require(`../../assets/svgs/logo/${detail.logo}`)} alt={detail.title} style={{ maxWidth: '170px', height: '100%' }}/>
            </LogoContainer>
            <MiniTitle style={{ marginBottom: '-3px' }}>
                {detail.minititle}
            </MiniTitle>
            <Title style={{ textAlign: 'center' }}>
                {detail.title[0]} <br /> <span style={{ fontFamily: "'Gilroy Bold', sans-serif", marginTop: '3px' }}>{detail.title[1]}</span>
            </Title>
            <BottomTitle fontsize= '11.7px' upper = {true} fontweight='800' width="100%" style={{ textAlign: 'center' }}>
                {detail.fromTo}
            </BottomTitle>
            <BottomTitle fontsize= '12px' width='100%' style={{ textAlign: 'center' }}>
                {detail.miniInfo}
            </BottomTitle>
        </Container>
        <div>
            <Link to={`/case/${detail.id}`}>
                <LinkText style={{ textAlign: 'center', marginTop: '10px' }}>
                    Read full case study <span>&gt;</span>
                </LinkText>
            </Link>
        </div>
    </MainContainer>
  )
}

export default Case
