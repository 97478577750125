import React from 'react'

// styles
import { MainContainer } from '../About/about.style'
import PrivacyImage from '../../assets/svgs/privacypolicy.svg'

// components
import Heading from '../../components/Heading/Heading'
import { Column, Inner, ImageContainer, Policies } from './policy.style'

const Policy = () => {
  return (
    <MainContainer>
        <Heading title='Privacy Policy'/>
        <Column>
            <h3 style={{ fontSize: '24px', textAlign: 'center', fontWeight: '600' }}>Privacy Policy for Social Ambience</h3>
             <ImageContainer>
                <img src={PrivacyImage} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
            </ImageContainer>
            <Inner>
                <p>At Social Ambience, accessible from www.socialambience.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that are collected and recorded by Social Ambience and how we use it.</p>
                <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us</p>
                <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Social Ambience. This policy is not applicable to any information collected offline or via channels other than this website</p>
            </Inner>
            <div style={{ marginTop: '40px' }}>
                <Policies dark={true} style={{ fontWeight: '600' }}>
                    Consent
                </Policies>
                <Policies>
                    By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                </Policies>
            </div>

            <div>
                <Policies dark={true}>
                    A. Personal data/Information we collect
                </Policies>
                <Policies>
                    <p>Social Ambience collects data from you, through our interactions with you and through our services for a variety of purposes described below, including to operate effectively and provide you with the best experiences with our services. You provide some of this data directly and we get some of it by collecting data about your interactions, use, and experience with our services and communications.</p>
                    <p>We rely on a variety of legal reasons and permissions (sometimes called “legal bases”) to process data, including with your consent, a balancing of legitimate interests, necessary to enter into and perform contracts and compliance with legal obligations, for a variety of purposes described below. </p>
                    <p>We also obtain data from third parties. We protect data obtained from third parties according to the practices described in this statement, plus any additional restrictions imposed by the source of the data.</p>
                    <p>These third-party sources vary over time and include: <br />
                    Data brokers from which we purchase demographic data to supplement the data we collect. <br />
                    Services that make user-generated content from their service available to others, such as local business reviews or public social media posts.</p>
                    <p>Communication services, including email providers and social networks, when you give us permission to access your data on such third-party services or networks.</p>
                    <p>Partners with which we offer co-branded services or engage in joint marketing activities.</p>
                    <p>Publicly available sources, such as open government databases.</p>
                    <p>You have choices when it comes to the services you use and the data you share. When you are asked to provide personal data, you can decline. Many of our Services require some personal data to operate and provide you with a service. If you choose not to provide data necessary to operate and provide you with a service, you cannot use that service. Likewise, where we need to collect personal data by law or to enter into or carry out a contract with you, and you do not provide the data, we will not be able to enter into the contract; or if this relates to an existing service you’re using, we may have to suspend or cancel it. We will notify you if this is the case at the time. Where providing the data is optional, and you choose not to share personal data, features like personalization that use the data will not work for you.</p>
                    <p>The data we collect depends on the context of your interactions with Social Ambience and the choices you make, the services and features you use, your location, and applicable law. </p>
                </Policies>
            </div>

            <div>
                <Policies dark={true}>
                    B. The data/Information we collect can include the following:
                </Policies>
                <Policies>
                    <p>Name and contact data: Your first and surname, email address, postal address, phone number, and other similar contact data.</p>
                    <p>Credentials: Passwords, password hints, and similar security information used for authentication and account access. </p>
                    <p>Demographic data: Data about you such as your age, gender, country, and preferred language. </p>
                    <p>Payment data: Data to process payments, such as your payment instrument number (such as a credit card number, debit card number,) and the security code associated with your payment instrument.</p>
                    <p>Content consumption data: Information about media content (e.g. TV, video, music, audio, textbooks, apps, and games) you access through our Services. </p>
                    <p>Voice data: Your voice data, such as the search queries or commands you speak, which may include background sounds.</p>
                    <p>Text, inking, and typing data: Text, inking, and typing data and related information. For example, when we collect inking data, we collect information about the placement of your inking instrument on your device. </p>
                    <p>Images: Images and related information, such as picture metadata. For example, we collect the image you provide when you use image-enabled service.</p>
                    <p>Contacts and relationships: Data about your contacts and relationships if you use a service to share information with others, manage contacts, communicate with others, or improve your productivity.</p>
                    <p>Social data: Information about your relationships and interactions between you, other people, and organizations, such as types of engagement (e.g. likes, dislikes, events, etc.) related to people and organizations.</p>
                    <p>Photos, images, songs, films, software and other media or documents you store, retrieve or otherwise process with our clouds</p>
                    <p>Video or recordings: Recordings of events and activities at Social Ambience buildings, retail spaces, and other locations. If you enter Social Ambience or other facilities or attend a Social Ambience event that is recorded, we may process your image and voice data. </p>
                    <p>Feedback and ratings: Information you provide to us and the content of messages you send to us, such as feedback, survey data, and product reviews you write. </p>
                </Policies>
            </div>

            <div>
                <Policies dark={true}>
                    C. How we use your information
                </Policies>
                <Policies>
                    <p>We use the information we collect in various ways, including:</p>
                    <p>Provide our Services, which includes digital advertising, securing and boosting the social accounts, as well as providing support and necessary activities to keep the accounts updated. It also includes sharing data, when it is required to provide the service or carry out the transactions you request.</p>
                    <p>Improve and develop our services.</p>
                    <p>Personalize our services and make recommendations.</p>
                    <p>Advertise and market to you, which includes sending promotional communications, targeting advertising, and presenting your relevant offers.</p>
                    <p>Provide, operate, and maintain our website.</p>
                    <p>Improve, personalize, and expand our website.</p>
                    <p>Understand and analyze how you use our website.</p>
                    <p>Develop new products, services, features, and functionality.</p>
                    <p>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes.</p>
                    <p>Send your emails.</p>
                    <p>Find and prevent fraud.</p>
                    <p>We also use the data to operate our business, which includes analyzing our performance, meeting our legal obligations, developing our workforce, and doing research.
                    When we process personal data about you, we do so with your consent and/or as necessary to provide the services you use, operate our business, meet our contractual and legal obligations, protect the security of our systems and our customers, or fulfill other legitimate interests of Services as described in this section and in the reasons we share personal data section of this privacy statement.</p>
                </Policies>
            </div>

            <div>
                <Policies dark={true}>
                    D. Other Matters
                </Policies>
                <Policies>
                    <p>Safety: We use data to protect the safety of our services and our customers. we reserve the right to block delivery of a communication or remove content if it violates our terms.</p>
                    <p>Updates: We use the data we collect to develop service updates and security patches. Updates and patches are intended to maximize your experience with our service, help you protect the privacy and security of your business, provide promoted services and ensure that your business is ready for such updates which can boost your business beyond the expectation.</p>
                    <p>Promotional communications: We use data we collect to deliver promotional communications. </p>
                    <p>Advertising: Social Ambience uses what you say in email, chat, video calls or voicemail, or your documents, photos or other personal files to target ads to you also we use data we collect through our interactions with you, through some of our Service, and on third-party web properties, for advertising in our services and on third-party properties. We may use automated processes to help make advertising more relevant to you. </p>
                    <p>Transacting commerce: We use data to carry out your transactions with us. For example, we process payment information to provide customers with services
                    Reporting and business operations: We use data to analyze our operations and perform business intelligence. This enables us to make informed decisions and report on the performance of our business. </p>
                    <p>Protecting rights and property: We use data to detect and prevent fraud, resolve disputes, enforce agreements, and protect our property. For example, we use data to confirm the validity of services. We may use automated processes to detect and prevent activities that violate our rights and the rights of others, such as fraud.</p>
                    <p>Legal compliance: We process information to comply with the law. For example, we use the age of our customers to ensure we meet our obligations to protect children’s privacy. We also process contact information and credentials to help customers exercise their data protection rights.</p>
                    <p>Research: With appropriate technical and organizational measures to safeguard individuals’ rights and freedoms, we use data to conduct research, including for public interest and scientific purposes.</p>
                </Policies>
            </div>

            <div>
                <Policies dark={true}>
                    E. Reasons we share personal data
                </Policies>
                <Policies>
                    <p>We share your personal data with your consent or as necessary to complete any transaction or provide any services you have requested or authorized. For example, we share your content with third parties when you tell us to do so. We share certain data, such as interaction data and diagnostic data to enable your organization to manage the Services. When you provide payment data to make a deal, we will share payment data with banks and other entities that process payment transactions or provide other financial services, and for fraud prevention and credit risk reduction.</p>
                    <p>In addition, we share personal data among Social Ambience controlled affiliates and subsidiaries. We also share personal data with vendors or agents working on our behalf for the purposes described in this statement. For example, companies we<span>&apos;</span>ve hired to provide customer service support or assist in protecting and securing our systems and services may need access to personal data to provide those functions. In such cases, these companies must abide by our data privacy and security requirements and are not allowed to use personal data they receive from us for any other purpose. We may also disclose personal data as part of a corporate transaction such as a merger or sale of assets. </p>
                    <p>Finally, we will retain, access, transfer, disclose and preserve personal data, including your content when we have a good faith belief that doing so is necessary to do any of the following:
                    Comply with applicable law or respond to valid legal process, including from law enforcement or other government agencies. </p>
                    <p>Protect our customers, for example, to prevent spam or attempts to defraud users of our service, or to help prevent the loss of life or serious injury of anyone.
                    Operate and maintain the security of our service, including to prevent or stop attack service networks.</p>
                    <p>Protect the rights or property of Service, including enforcing the terms governing the use of the services – however, if we receive information indicating that someone is using our services to traffic in the stolen intellectual or physical property of Social Ambience, we will not inspect a customer<span>&apos;</span>s private content ourselves, but we may refer the matter to law enforcement.</p>
                </Policies>
            </div>

            <div>
                <Policies dark={true}>
                    Log Files
                </Policies>
                <Policies>
                    <p>Social Ambience follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services<span>&apos;</span> analytics. The information collected by log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users<span>&apos;</span> movement on the website, and gathering demographic information. Our Privacy Policy was created with the help of the Privacy Policy Generator and the Disclaimer Generator.</p>
                </Policies>
            </div>

            <div>
                <Policies dark={true}>
                    Cookies and Web Beacons
                </Policies>
                <Policies>
                    <p>Like any other website, Social Ambience uses <span>&apos;</span>cookies<span>&apos;</span>. These cookies are used to store information including visitors<span>&apos;</span> preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users<span>&apos;</span> experience by customizing our web page content based on visitors<span>&apos;</span> browser type and/or other information.</p>
                </Policies>
            </div>

            <div>
                <Policies dark={true}>
                    Third-Party Privacy Policies
                </Policies>
                <Policies>
                    <p>Social Ambience<span>&apos;</span>s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</p>
                    <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers<span>&apos;</span> respective websites.</p>
                </Policies>
            </div>

            <div>
                <Policies dark={true}>
                   CCPA Privacy Rights (Do Not Sell My Personal Information)
                </Policies>
                <Policies>
                    <p>Under the CCPA, among other rights, consumers have the right to: <br />
                    Request that a business that collects a consumer<span>&apos;</span>s personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
                    </p>
                    <p>Request that a business deletes any personal data about the consumer that a business has collected.</p>
                    <p>Request that a business that sells a consumer<span>&apos;</span>s personal data, not sell the consumer<span>&apos;</span>s personal data.</p>
                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
                    <p>GDPR Data Protection Rights <br /> We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
                    <p>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
                    <p>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>
                    <p>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p>
                    <p>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
                    <p>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p>
                    <p>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
                </Policies>
            </div>

             <div>
                <Policies dark={true}>
                    <span>Children<span>&apos;</span>s Information</span>
                </Policies>
                <Policies>
                    <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
                    <p>Social Ambience does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
                </Policies>
            </div>

            <Inner style={{ width: '100%', margin: '80px 0 0 0', gap: '0' }}>
                <Policies dark={true} style={{ fontSize: '1.5rem' }}>
                    CHANGES TO THIS PRIVACY STATEMENT
                </Policies>
                <Policies>
                    <p>
                        We reserve the right to change or update this statement from time to time. Please check our online and mobile resources periodically for such changes since all information collected is subject to the statement in place at that time. Typically, we will indicate the effective/amendment date at the beginning of this statement. If we feel it is appropriate, or if the law requires, we’ll also provide a summary of changes we’ve made near the end of the new statement.
                    </p>
                </Policies>
            </Inner>

            <Inner style={{ width: '100%', margin: '0', gap: '0' }}>
                <Policies dark={true} style={{ fontSize: '1.5rem' }}>
                    CONTACTING US
                </Policies>
                <Policies>
                    <p>
                        If you have questions about our privacy statement or privacy practices, please contact us at: <br />
                        Social Ambience Pvt. Ltd. <br />
                        Tarkeshwor-11, Kathmandu Nepal.
                    </p>
                </Policies>
            </Inner>

        </Column>
    </MainContainer>
  )
}

export default Policy
