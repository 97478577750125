import React from 'react'

// data
import REVIEWS from '../../../constants/Reviews'

// styles
import { MarginContainer } from '../../../assets/styles/Global.styled'
import { Title } from '../Home.styled'

// components
import ReviewCard from '../../../components/Card/ReviewCard'
import Slide from '../../../components/Carousel/Slide'

const Reviews = () => {
  return (
    <MarginContainer style={{ marginTop: '60px', marginBottom: '-20px' }}>
        <Title dark={true} fontmax= '2.12rem' fontmin='1.2rem' bold={true}>
            Reviews from our Clients.
        </Title>
        <Slide>
           {REVIEWS.map((details, id) => (
            <ReviewCard key={id} details = {details}/>
           ))}
        </Slide>
    </MarginContainer>
  )
}

export default Reviews
