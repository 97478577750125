import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { usePopupButton } from '../../hooks/usePopupButton'
import SOCIAL_MEDIA from '../../constants/SocialMedia'

import { FooterContainer, FooterLeft, FooterRight, Icons, LinkContainer, LinkText, Wrapper } from './Footer.styled'

import Logo from '../../assets/svgs/purplelogo.svg'
import LightLogo from '../../assets/svgs/lightLogo.svg'

const Footer = () => {
  const links = [
    {
      label: 'Terms & Conditions',
      path: '/policy'
    },
    {
      label: 'Privacy Policy',
      path: '/policy'
    }
  ]

  const [mode, setMode] = useState('white')
  const { setOpen, popupModal } = usePopupButton()

  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/') {
      setMode('white')
    } else {
      setMode('purple')
    }
  }, [location])

  return (
    <Wrapper mode = {mode}>
      <FooterContainer>
        <FooterLeft>
          <LinkContainer>
              {
                  links.map((link, id) => {
                    return (
                      <Link key={id} to={link.path}>
                          <LinkText mode = {mode}>
                              {link.label}
                          </LinkText>
                      </Link>
                    )
                  })
              }

              <span onClick={() => setOpen(true)}>
                  <LinkText mode = {mode} style={{ cursor: 'pointer' }}>
                      Contact
                  </LinkText>
              </span>
              {popupModal}
          </LinkContainer>

          <LinkContainer>
          {
              SOCIAL_MEDIA.map((social, id) => {
                return (
                  <a key={id} href={social.path} target="_blank" rel="noreferrer" >
                      <Icons key={id}>
                          <img src={require(`../../assets/svgs/${social.icon}`)} alt={social.name} style={{
                            maxWidth: '26px',
                            maxHeight: '26px',
                            filter: mode === 'purple' ? 'invert(100%) sepia(0%) saturate(0%) hue-rotate(152deg) brightness(102%) contrast(103%)' : 'invert(23%) sepia(10%) saturate(7108%) hue-rotate(263deg) brightness(101%) contrast(97%)'
                          }}/>
                      </Icons>
                  </a>
                )
              })
          }
          </LinkContainer>

          <LinkContainer>
            <LinkText mode = {mode}>
                <span>&copy; 2022 Social Ambience</span>
            </LinkText>
          </LinkContainer>

        </FooterLeft>

        <FooterRight>
            <img src={mode === 'white' ? Logo : LightLogo} alt="" style={{ maxHeight: '145%', maxWidth: '100%' }}/>
        </FooterRight>
      </FooterContainer>
    </Wrapper>
  )
}

export default Footer
