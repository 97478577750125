import styled from 'styled-components'

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width : 45%;
    max-width : 400px;
    gap : 20px;
    margin-bottom: 50px;
`
export const Container = styled.div`
    width : 100%;
    max-width : 400px;
    min-height : 370px;
    border-radius: 17px;
    background:linear-gradient(90deg, rgba(231, 230, 243, 1) -0%, rgba(212, 204, 255, 1) 100%);
    gap :5px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding: 20px;
`
