import styled from 'styled-components'
import { FlexBox } from '../../assets/styles/Global.styled'
import '../../assets/fonts/index.css'
import Bullet from '../../assets/svgs/bullet.svg'

export const Container = styled.div`
  width: 100vw;
  background-color: ${(props) => props.theme.colors.primary.purple};
  margin-top: 4rem;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 40px 0;
  gap: 2rem;
  flex-wrap: wrap;
  min-height:  ${(props) => props.heightXL};

  @media (max-width: 560px) {
      min-height:  ${(props) => props.heightSM};
  }
  @media (max-width: 330px) {
      min-height:  ${(props) => props.heightXS};
  }
`
export const Child = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
`
export const Heading = styled.h1`
  font-size: clamp(1.75rem, -0.75rem + 8vw, 3.75rem);
  color: ${(props) => props.theme.text.light};
  text-transform: uppercase;
  font-family : 'Raleway', sans-serif;
  font-weight:  900;
`
export const CenterText = styled.p`
  font-size: clamp(0.9rem, 0.15rem + 2.4vw, 1.5rem);
  text-align: center;
  color: ${(props) => props.theme.text.light};
  line-height: clamp(20px, 0.15rem + 2.4vw, 30px);
  font-weight: 300;
`
export const Slider = styled(Child)`
  min-height: 23rem;
  margin-top: 50px;
`
export const Title = styled.h2`
  font-size: ${(props) => `clamp(${props.fontmin}, 0.1125rem + 5.08vw, ${props.fontmax})`};
  color: ${(props) => props.dark ? props.theme.colors.primary.purple : props.theme.text.light};
  text-align: center;
  font-weight: ${(props) => props.bold ? 'bolder' : 'lighter'};
`
export const Companies = styled.div`
  max-width: 780px;
  width: 100%;
  ${FlexBox}
  margin-top: 30px;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 960px) {
      justify-content: space-around;
  }

`
export const Individual = styled.div`
  width: ${(props) => props.big ? '32%' : '23%'};
  display: flex;
  margin: 20px 0;
  justify-content:  ${(props) => props.right ? 'flex-end' : 'flex-start'};
  align-items: center;
  height : ${(props) => props.lastRow ? '85px' : '52px'};

  /* @media (max-width: 960px) {
      height: 50px;
      margin: 15px 0px;
  } */

   @media (max-width: 880px) {
      height: 40px;
      width: 23%;
      justify-content: center;
  }
   @media (max-width: 680px) {
      width: 43%;
      height: 30px;
      margin: 15px 0;
  }
`
export const TextSpan = styled.span`
   color: ${(props) => props.dark ? props.theme.colors.primary.purple : props.theme.text.light};
   text-align: center;
   font-size: clamp(0.9rem, 0.4625rem + 1.4vw, 1.25rem);
   font-weight: ${(props) => props.bold ? 'bolder' : 'lighter'};
`

export const InnerContainer = styled.div`
  width: 70%;
  margin-left: 15%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
@media (max-width: 992px) {
       width: 100%;
       padding:0px 10px;
       margin-left:0px;
    }
`
export const Text = styled.span`
  font-size: clamp(1.4rem, -0.6rem + 6.4vw, 3rem);
  font-weight: bold;
  color : ${(props) => props.theme.text.light};
  margin: 25px 0;
`
export const Break = styled.br`
  @media (max-width: 680px) {
        display: none;
    }
`
export const ListItem = styled.li`
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 10px;
  background-image: url(${Bullet});
  padding: 15px 0 10px 25px;
  color: #fff;
  font-size: clamp(1rem, 0.75rem + 0.8vw, 1.2rem);
  margin-bottom: -7px;
  font-weight: 400;
`
export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
`
export const Element = styled.div`
  width: 67.5%;
  display: flex;
  flex-direction: column;
  margin-top: -15px;
  margin-left: -25px;

    @media (max-width: 992px) {
       width: 100%;
       padding-left: 20px;
    }
`

export const Contact = styled.div`
  margin-top: -15px;
  width : 32.5%;
  display: flex;
  margin-left:25px;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 992px) {
       width: 100%;
    }
`
