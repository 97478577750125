const OPENPOSITION = [
  {
    position: "FB Advertisement Assistant",
    company: "Social Ambience",
    location: "Kathmandu, Nepal (On-site)",
  },
  // {
  //   position: 'FB Advertisement Assistant',
  //   company: 'Social Ambience',
  //   location: 'Kathmandu, Nepal (On-site)'
  // },
  // {
  //   position: 'FB Advertisement Assistant',
  //   company: 'Social Ambience',
  //   location: 'Kathmandu, Nepal (On-site)'
  // }
];
export default OPENPOSITION;
