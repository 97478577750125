const THEME = {
  colors: {
    primary: {
      purple: '#792B7C'
    }
  },
  text: {
    light: '#FFFFFF'
  }
}

export default THEME
