import React from 'react'
import { Link } from 'react-router-dom'

import { GradientBox, InnerContainer, Row, Name, Price, Percent } from './chart.styled'
import { Item, Text } from '../Banner/Banner.styled'

import UpArrow from '../../assets/svgs/uparrow.svg'
import CircleIcon from '../../assets/svgs/timeline/circle.svg'

const Chart = ({ text, sales, salesPercent, arrowUp, allSales, timeline, width, height, minWidth, minHeight }) => {
  return (
    <GradientBox width={width} height={height} minWidth={minWidth} minHeight={minHeight}>
        <InnerContainer>
            <Item>
                <Text font= '0.65rem' style={{ fontWeight: '600' }}>
                    {text || 'Total Sales'}
                </Text>
                <Link to='/'>
                    <Text font = '0.65rem' color = '#456FBE'>
                    View report
                    </Text>
                </Link>
            </Item>

            <Item style={{ marginBottom: '8px' }}>
                <Text font= '1.25rem' bold={true} color='#202223' style={{ fontFamily: 'Myriad Pro Regular', position: 'relative' }}>
                    <span>&euro;{sales}</span>
                    <img src={CircleIcon} style = {{ position: 'absolute', bottom: '-5px', left: '-10px', maxWidth: '143px', height: '32px' }}/>
                </Text>
                <Link to='/'>
                    <Text font = '1.25rem' bold={true} color = '#2A9E61' style={{ fontFamily: 'Myriad Pro Regular' }}>
                    <span style={{ display: 'flex', flexDirection: 'row' }}>
                        { arrowUp && <img src ={UpArrow} style={{ width: '14px', height: '22px' }}/>} {salesPercent}
                    </span>
                    </Text>
                </Link>
            </Item>

            {
                allSales.length > 0 && allSales.map((item, id) =>
                    <Row key = {id}>
                        <Name>{item[0]}</Name>
                        <Price> {item[1]}</Price>
                        <Percent> {item[2] === '-' ? '-' : <><img src ={UpArrow} style={{ width: '7px', height: '10px' }}/> {item[2]}</>}</Percent>
                    </Row>
                )
            }

            <Item style={{ marginTop: '10px' }}>
                <Text font= '0.5rem' color='#232323' style={{ letterSpacing: '0.64px', fontWeight: 'bold' }}>
                    SALES OVER TIME
                </Text>
            </Item>

            <Item style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center' }}>
                <img src={require(`../../assets/svgs/timeline/${timeline}`)} alt="" style={{ maxHeight: '100%', maxWidth: '100%' }}/>
            </Item>
        </InnerContainer>
    </GradientBox>
  )
}

export default Chart
