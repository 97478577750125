import styled, { css } from 'styled-components'
import Rating from '@mui/material/Rating'

import '../../assets/fonts/index.css'
import '../../assets/fonts/MYRIADPRO-SEMIBOLD.woff'
import '../../assets/fonts/MYRIADPRO-REGULAR.woff'
import { FlexBox } from '../../assets/styles/Global.styled'

export const CardBox = styled.div`
    width: 100%;
    min-height: 23rem;
    border-radius: 17px;
    transition: all 0.3s ease-out;
    background:linear-gradient(90deg, rgba(231, 230, 243, 1) -0%, rgba(212, 204, 255, 1) 100%);
    display: flex;
    justify-content: space-between;
    cursor: grab;
    /* box-shadow: 2px 11px 40px -5px rgba(121,43,124,0.74); */
    
    /* box-shadow: 0 6px 3px -3px #c5bcf5;
    box-shadow: 1px 13px 12px 2px rgba(197,188,245,0.75); */

    background-image: linear-gradient(90deg, rgba(231, 230, 243, 1) 0%, rgba(212, 204, 255, 1) 100%), linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, #c5bcf5 100%);
    background-origin: border-box;
    background-clip: content-box,  border-box;

    @media (max-width: 1225px) {
        min-height: 30rem;
    }
    @media (max-width: 1005px) {
        min-height: 38rem;
    }
    @media (max-width: 1005px) {
        min-height: 38rem;
    }
    @media (max-width: 270px) {
        height: 660px;
        overflow: scroll;
    }
    @media (max-width: 960px) {
       flex-direction: column;
      
       gap: 1rem;
       display: ${(props) => props.active ? 'flex' : 'none'};
       min-height:550px;
    }

  

  
  

`
export const Box = styled.div`
    width : 100%;
    display: flex;
    justify-content: space-between;
    padding: 40px;
    @media (max-width: 960px) {
       flex-direction: column;
       padding:20px;
       gap: 1rem;
    }

  
`

export const LeftChild = styled.div`
    width: 65%;
    display: flex;
    flex-direction: column;
    @media (max-width: 960px) {
        width: 100%;
    }
`
export const RightChild = styled.div`
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 10px;
    @media (max-width: 960px) {
        width: 100%;
        padding-left: 0;
    }
`
export const LogoContainer = styled.div`
    width: 100%;
    margin-bottom: 15px;
    height: 70px;
`
export const IndividualLogoContainer = styled.div`
    width: 100%;
    margin-bottom: 15px;
    height: 100px;
    display: flex;
    justify-content: flex-end;
`
export const MiniTitle = styled.p`
    color : ${(props) => props.theme.colors.primary.purple};
    font-family : 'Myriad Pro Bold';
    text-transform: uppercase;
    font-size : 0.7rem;
    letter-spacing : 1.6px;
    margin-bottom: 10px;
`
export const Title = styled.h2`
    color : ${(props) => props.theme.colors.primary.purple};
    font-size: clamp(1.7rem, 0.2rem + 4.8vw, 2.9rem);
    font-weight: 800;
    margin-bottom: 8px;
`
export const BottomTitle = styled.div`
    color : ${(props) => props.theme.colors.primary.purple};
    text-transform: ${(props) => props.upper ? 'uppercase' : null};
    font-size: ${(props) => props.fontsize};
    margin-bottom: 10px;
    font-weight: ${(props) => props.fontweight || 500};
    width:  ${(props) => props.width ? props.width : '75%'};
    /* line-height: 12px; */

    /* @media (max-width: 290px) {
        display: ${(props) => props.hide && 'none'};
    } */
`
export const LinkText = styled.h1`
    font-size: 20px;
    text-align: right;
    font-weight: 600;
    background: linear-gradient(90deg, rgba(48, 96, 171, 1) 0%, rgba(75, 168, 222, 1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor : pointer;
      @media (max-width: 992px) {
        font-size:14px;
        cursor:pointer;
    }
`

export const ReviewBox = styled.div`
    width: 65%;
    margin-left: 17.5%;
    display: flex;
    justify-content: center;
    @media (max-width: 1170px) {
      width: 90%;
      margin-left: 5%;
    }
    @media (max-width: 960px) {
        flex-direction: column;
    }
`

export const Person = styled.div`
    width: 25%;
    ${FlexBox}
    flex-direction: column;
    @media (max-width: 960px) {
        width: 100%;
    }
`
export const ImageContainer = styled.div`
    display: block;
    position: relative;
    width : 118px;
    height: 118px;
`
export const TopLeft = styled.div`
    position: absolute;
    left: -30%;
    top: 0;
    width: 40%;
`
export const Comment = styled.div`
    width: 75%;
    @media (max-width: 960px) {
        width: 100%;
        text-align: center;
        margin-top: 15px;
    }
`
export const Message = styled.span`
    color : #000;
    font-size: 0.89rem;
    margin-top: 15px;
    display: block;
`
const text = css`
    text-align: center;
    color : ${(props) => props.theme.colors.primary.purple};
`

export const Name = styled.span`
    ${text}
    font-size : 1.063rem;
    margin-top: 10px;
    font-weight: bold;
`
export const Description = styled.span`
    ${text}
    font-size : 0.75rem;
`

export const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#792B7C'
  }
})

export const Rounded = styled.div`
    background-color : #fff;
    border-radius : 25px;
    width: 100%;
    margin-left: 10%;
    min-height: 30%;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    position: relative;

     @media (max-width: 992px) {
       width: 100%;
       margin-left: 0;
       margin-top: 100px;
    }
`
export const InnerContainer = styled.div`
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    padding: 10px 0;


`
export const Image = styled.img`
    width: 43px;
    height: 29px;
    margin: 0 0 20px 25px;
`

export const Heading = styled.h3`
    font-size: clamp(1.2rem, 0.6062rem + 1.9vw, 1.675rem);
    font-weight: bolder;
    text-align: left;
`

export const DownArrow = styled.div`
    width: 74px;
    height: 41px;
    position: absolute;
    left: 37%;
    top: -9.5%;
`
