import React from 'react'

import {
  Descriptions,
  DescriptionItem,
  DescriptionContainer
} from './casestudy.style'
import FirstImage from '../../assets/svgs/FirstImage.png'
import SecondImage from '../../assets/svgs/SecondImage.png'
import TopImage from '../../assets/svgs/TopImage.png'

const WildlifeTree = () => {
  return (
    <>
      <Descriptions>
        <DescriptionContainer>
          <DescriptionItem bold={true}>Before we took<br />
            over the account :</DescriptionItem>
          <DescriptionItem>
            <p style={{ marginTop: 30 }}>
              Only one campaign was launched with a Cost per acquisition (CPA) of $408.26.
            </p>

          </DescriptionItem>
        </DescriptionContainer>
      </Descriptions>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', margin: '50px 0px' }}>
        <img src={TopImage} alt="image_here" style={{ maxWidth: '100%', maxHeight: '100%' }} />
      </div>
      <Descriptions dark={true}>
        <DescriptionContainer>
          <DescriptionItem bold={true}>
            Step 1:
            <br /> Finding opportunities{' '}
          </DescriptionItem>
          <DescriptionItem>
            <p>
              On the first glimpse of the ad account, they were not running the retargeting ads
            </p>
            <p>
              They were only testing one audience in the TOF campaign, which meant they were
              spending way too much on the Cost Per Acquisition.
            </p>
            <p>
              This could be solved by testing more audiences and creatives for the brand.
            </p>
            <p>
              The conversion rate wasn’t even 1.1%, so we had to focus on that too.
            </p>
            <p>
              So it was clear what we needed to do.             </p>
            <p>
              Our team sat down and discussed the audiences, ad angles, and problems and solutions.
              We came up with:
            </p>
            <ul>
              <li>Start running retargeting ads with testimonials and offers. </li>
              <li>A varied audience in the TOF campaign like Child Big Brands, Parenting Magazines,Broad, Parents with children aged 3-8, and others.</li>
              <li>Varied creatives and copy with angles like the importance of conservation, giving the gift of knowledge, and getting your kids off the screens and into active play.</li>
            </ul>
          </DescriptionItem>
        </DescriptionContainer>
      </Descriptions>
      <Descriptions>
        <DescriptionContainer>
          <DescriptionItem bold={true}>First Month:</DescriptionItem>
          <DescriptionItem>
            <p>
              From Jul 29 to Aug 31, we got 84 purchases after spending $3,485.28 and ROAS in that
              period was 0.83, which by no means is a good number.
            </p>
            <p>
              So initially, we had a CPA of $408.26. After we started running a campaign, the CPA
              decreased from $408.26 to $33.61 Which is a whopping decrease of 91%!
            </p>

          </DescriptionItem>
        </DescriptionContainer>
      </Descriptions>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', margin: '50px 0px' }}>
        <img src={FirstImage} alt="image_here" style={{ maxWidth: '100%', maxHeight: '100%' }} />
      </div>
      <Descriptions dark={true}>
        <DescriptionContainer>
          <DescriptionItem bold={true}>Step 2:<br />
            Optimizing for<br />
            best results.</DescriptionItem>
          <DescriptionItem>
            <p>
              Our goal was to get the BREAK EVEN at the initial phase i.e. 2.15 ROAS. We changed their
              offer in the second month from 15% OFF to 50% OFF on the first order with the hope of
              getting a box subscription on a monthly basis. We calculated their Lifetime Value (LTV).
            </p>
            <p>
              Child Brand Stacked and Parenting Magazines stacked showed some potential in the first
              month.
            </p>
            <p>
              In the meanwhile, We also introduced a new offer “Free Upgrade To edZOOcation when you
              buy stuffed animals”
            </p>

          </DescriptionItem>
        </DescriptionContainer>
      </Descriptions>
      <Descriptions>
        <DescriptionContainer>
          <DescriptionItem bold={true}>Step 3:<br />
            Scaling the account</DescriptionItem>
          <DescriptionItem>
            <p>
              We’re only 21 days into the third month, and we have a purchase of 183 with a ROAS of 3.62.
              The CPA lowered by 45% i.e. $18.34 and the subscriber rate increased by
            </p>
            <p>
              As you can see, the October Animal of the month proved to be the best thing. We found
              some winning and potential audiences, and the offer we introduced finally started to peak.
            </p>

          </DescriptionItem>
        </DescriptionContainer>
      </Descriptions>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', margin: '50px 0px' }}>
        <img src={SecondImage} alt="image_here" style={{ maxWidth: '100%', maxHeight: '100%' }} />
      </div>
      <Descriptions dark={true}>
        <DescriptionContainer>
          <DescriptionItem bold={true}>Step 4:<br />
            Opportunities ahead</DescriptionItem>
          <DescriptionItem>
            <p>
              We have set up a goal to garner 300 subscribers for the next month, and for that, we now
              have everything in place (winning ad combinations, creatives, copies, and offers).
              Not only will we do that, but we will also focus on creating new winning combinations
              simultaneously to scale the account.
            </p>

          </DescriptionItem>
        </DescriptionContainer>
      </Descriptions>
      <Descriptions>
        <DescriptionContainer>
          <DescriptionItem bold={true}>Summary:</DescriptionItem>
          <DescriptionItem>
            <p>
              We slashed the cost per acquisition from $408 to $18 and scaled the business from getting Summary: one subscriber a month to 183 subscribers and will scale it past x results in the next 30 days.
            </p>

          </DescriptionItem>
        </DescriptionContainer>
      </Descriptions>
    </>
  )
}

export default WildlifeTree
