import React from 'react'
import Email from '../../components/Email/Email'

import { Descriptions, DescriptionItem, DescriptionContainer, Absolute, BeforeAfter, RowContainer, Item, MiniTitle, Row } from './casestudy.style'
import { GradientBox } from '../../components/Chart/chart.styled'

import PrivacyLogo from '../../assets/svgs/privacy.svg'
// import UTM from '../../assets/svgs/UTM.svg'
import WildfaceChart from '../../assets/svgs/timeline/WildfaceChart.svg'

import Table from '../../components/Table/Table'
import DateText from '../../components/DateText/DateText'
import Chart from '../../components/Chart/Chart'
import SampleAds from './SampleAds'

const Wildface = () => {
  return (
    <>
        <Descriptions>
            <DescriptionContainer>
                <DescriptionItem bold={true}>
                    What Happened?
                </DescriptionItem>
                <DescriptionItem>
                    <p>When we got access to their ad account, the first step was to dig out the ad account and find the best performing ads and the audiences.</p>
                    <p>Out of all the ads, they were running, they only had one ad as the winner.</p>
                    <p>Since there was only one winning ad, we did not do any scaling because that would have been a risk to the ROI of the entire enterprise. </p>
                    <p>We then tested different ad angles, creatives, and offers to get more winning ads. </p>
                    <p>Once we did that, we got 5 winning ads. </p>
                    <p>So now it became much easier to actually scale the ads without hampering the ROI of the business. </p>
                    <p>Doing this got the company a ROAS of around 2.6 - a 50% increase in profits! (36k amount in revenue!) in initial month.</p>
                    <p>The ROAS stayed consistent for the entire month before the launch of iOS 14.</p>
                </DescriptionItem>
            </DescriptionContainer>
        </Descriptions>

         <Descriptions dark={true} style={{ position: 'relative' }}>
          <DescriptionContainer>
              <DescriptionItem bold={true} style={{ zIndex: 10 }}>
                  The Aftermath Of iOS 14
              </DescriptionItem>
              <DescriptionItem>
                <p>Right from the first week, we noticed a difference between the data in the Ad Manager and Shopify. </p>
                <p>We realized something - Facebook couldn’t track the purchases from iOS devices. </p>
                <p>Facebook stated that there were 15% fewer conversions. </p>
                <p>But we noticed that the numbers were almost double, clocking at about 30% fewer conversions. </p>
                <p>And we’ll be honest: </p>
                <p>We did not know what to do for the next week. </p>
                <p>But we knew we need to do something quick.</p>
              </DescriptionItem>
          </DescriptionContainer>
          <Absolute style={{ left: 0 }}>
            <img src={PrivacyLogo} alt="" style={{ height: '100%', maxWidth: '100%' }}/>
          </Absolute>
        </Descriptions>

        <Descriptions style={{ position: 'relative' }}>
          <DescriptionContainer>
              <DescriptionItem bold={true} style={{ zIndex: 10 }}>
                  What Happened Next?
              </DescriptionItem>
              <DescriptionItem>
                <p>Since the tracking went haywire, accurate data was hard to come by. </p>
                <p>This meant the businesses we were working with could be at a loss. </p>
                <p>So we switched to damage control during the second week of iOS 14 and found out about something called Urchin Tracking Module (UTM). </p>
                <p>With this UTM, we could go to the backend of either Google Analytics or Shopify to get the actual data. </p>
                <p>When we found out that this little trick actually worked, we encoded it to every ad we had. </p>
                <p>This allowed us to get the true data and helped us plan the ads accordingly. </p>
                <p>We completely disregarded the data from the Ad Manager and stuck to the data from the website backend. </p>
                <p>Wildface NOW has a ROAS of 3.9!</p>
              </DescriptionItem>
          </DescriptionContainer>
          {/* <Absolute style={{ left: 0, bottom: ' -35%', maxWidth: '580px' }}>
            <img src={UTM} alt="" style={{ height: '100%', maxWidth: '100%' }}/>
          </Absolute> */}
        </Descriptions>

        <BeforeAfter style={{ padding: '100px 0' }}>
          <Table text='Before Start of the Project: Ad Spend' width= '892px' height= '417px' img='wildface3.svg' top='-10%'/>
          <Table text='Before Start of the Project: Ad Spend' width= '894px' height= '343px' img='wildface2.svg' top='-10%'/>
          <RowContainer style ={{ gap: '20px', alignItems: 'baseline' }}>
            <Item>
              <MiniTitle dark={true} style={{ textAlign: 'left' }}>
                Before Start of the Project: Sales
              </MiniTitle>
              <DateText date='Dec 1-Dec 30,2021' compare ='compared to Oct 2-Oct 31, 2021' content = 'flex-start'/>
              <Chart sales='1,344.17' salesPercent='102' arrowUp ={true} allSales={[['Online Store', '$1,112.54', '74%'], ['Ulitmate Special Offers', '$321.63', '-'], ['', '', '-']]} timeline='wildface4.svg' width='392px' height='355px' minWidth='90%' minHeight='500px'/>
            </Item>

            <Item>
              <MiniTitle dark={true} style={{ textAlign: 'left' }}>
                After the Project: Sales
              </MiniTitle>
              <DateText date='May 1-Jul 31,2021' compare ='compared to Jan 29 Apr 30, 2021' content = 'flex-start'/>
              <Chart sales='140,928.67' salesPercent='21' arrowUp ={true} allSales={[['Online Store', '$133,147.12', '20%'], ['Ulitmate Special Offers', '$6,721.84', '35%'], ['ReConvert Upsell & Cross Sell', '$1,009.72', '80%'], ['Shop', '$49.99', '-']]} timeline='wildface6.svg' width='392px' height='355px' minWidth='90%' minHeight='500px'/>
            </Item>
          </RowContainer>

          <MiniTitle dark={true} style={{ marginTop: '40px', marginLeft: '-5px' }}>
                Shopify
          </MiniTitle>
          <DateText date='Mar 1-Mar 31,2021' compare ='compared to Jan 29-Feb 28, 2021'/>
          <Row style={{ marginTop: '-8px' }}>
            <Chart sales='140,928.67' salesPercent='21' arrowUp ={true} allSales={[['Online Store', '$133,147.12', '20%'], ['Ulitmate Special Offers', '$6,721.84', '35%'], ['ReConvert Upsell & Cross Sell', '$1,009.72', '80%'], ['Shop', '$49.99', '-']]} timeline='wildface6.svg' width='392px' height='355px' minWidth='38%' minHeight='600px'/>
          </Row>

          <Row>
            <GradientBox width = '391px' height = '202px' minWidth='38%' minHeight='300px'>
              <img src={WildfaceChart} alt="table" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
            </GradientBox>
          </Row>

        </BeforeAfter>

        <SampleAds dark={false} imgs = {['wf-ad1.jpg', 'wf-ad2.jpg', 'wf-ad3.jpg']} mb='-50px'/>

        <Descriptions dark={true}>
            <DescriptionContainer>
                <DescriptionItem bold={true}>
                    Current Scenario
                </DescriptionItem>
                <DescriptionItem>
                    <p>We continue to follow the above process on WILDFACE  and it is now maintaining a ROAS of 3.9. </p>
                    <p>We will continue to test other variables and have backups as we aim for a higher ROAS.</p>
                </DescriptionItem>
            </DescriptionContainer>
        </Descriptions>

        <Email text1 = {['So, What do you say?']}
        text2 ={['Ready to kick this off?']}
        />
    </>
  )
}

export default Wildface
