import React from 'react'
import { useParams } from 'react-router-dom'

// styles
import { MainContainer } from '../About/about.style'
import { BannerAbsolute, BannerContainer, CardContainer, Title, Wrapper } from './casestudy.style'
import Card from '../../components/Card/Card'

import CASE from '../../constants/Case'
import Banner from '../../assets/svgs/casebanner.svg'
import BeweegPot from './BeweegPot'
import Wildface from './Wildface'
import WildlifeTree from './WildlifeTree'

const CaseStudy = () => {
  const { caseid } = useParams()
  const index = CASE.findIndex((item) => item.id.toString() === caseid)

  return (
    <MainContainer>
        <BannerContainer>
            <img src={Banner} alt="banner" style={{ width: '100%' }}/>
            <Wrapper>
              <BannerAbsolute>
                  <span style={{ color: '#fff', textTransform: 'uppercase', fontSize: 'clamp(1rem, -1.5rem + 5vw, 1.625rem)', fontWeight: '700', lineHeight: '43px', letterSpacing: '7.28px' }}>
                      Case study
                  </span>
                  <Title>
                    {}
                      {CASE[index].title[0]}
                  </Title>
              </BannerAbsolute>
            </Wrapper>

            <CardContainer>
              <Card details={CASE[index]} individual={true} hide = 'no'/>
            </CardContainer>
        </BannerContainer>
        {caseid === '1'
          ? <BeweegPot/>
          : caseid === '2'
            ? <Wildface/>
            : caseid === '3'
              ? <WildlifeTree/>
              : null
        }

    </MainContainer>
  )
}

export default CaseStudy
