import React, { useState } from 'react'
import { InlineWidget, PopupModal } from 'react-calendly'

export const usePopupButton = () => {
  const [open, setOpen] = useState()
  const url = 'https://calendly.com/dipeshsapkota/paidads?month=2022-12'
  const popupModal = <PopupModal
              url={url}
              onModalClose={() => setOpen(false)}
              open={open}
              rootElement={document.getElementById('root')}
            />

  const calendyDisplay = <InlineWidget url={url} styles={{ height: 400 }} />
  return { popupModal, open, calendyDisplay, setOpen }
}
