import React, { useContext } from 'react'
import Draggable from 'react-draggable'
import { Link } from 'react-router-dom'
import useWindowDimensions from '../../hooks/useDimensions'
import DisplayContext from '../Carousel/DisplayContext'

import { BottomTitle, CardBox, LeftChild, LinkText, LogoContainer, MiniTitle, RightChild, Title, Box, IndividualLogoContainer } from './Card.styled'
const Card = ({ details, individual }) => {
  const value = useContext(DisplayContext)
  const { id, logo, minititle, title, timePeriod, fromTo, miniInfo, detail, hide } = details
  const current = useWindowDimensions()

  return (
    <Draggable
    disabled={current.width < 530}
    axis={individual ? 'none' : 'x'}
    onStop={(e, data) => value.trackPos(data)}
    bounds= "parent"
    >
    <CardBox active = {individual ? true : value.active}>
      <Box style={value.styling} active = {value.active}>
        <LeftChild>
         { !individual && <LogoContainer >
            <img src={require(`../../assets/svgs/logo/${logo}`)} alt={title} style={{ maxWidth: '170px', height: '100%' }}/>
          </LogoContainer>}
          <MiniTitle >
            {minititle}
          </MiniTitle>
          <Title>
            {title[0]} <br /> <span style={{ fontFamily: "'Gilroy Bold', sans-serif" }}>{title[1]}</span>
          </Title>
          <BottomTitle fontsize='19px' upper = {true} style={{ fontFamily: "'Gilroy Bold', sans-serif", fontWeight: 900, marginBottom: '20px' }}>
            {timePeriod}
          </BottomTitle>
          <BottomTitle fontsize= {individual ? '1rem' : '11.7px'} upper = {true} fontweight='800' width="100%">
            {fromTo}
          </BottomTitle>
          <BottomTitle fontsize= {individual ? '0.9rem' : '11px'} width='100%'>
            {miniInfo}
          </BottomTitle>
        </LeftChild>

        <RightChild>
          <BottomTitle fontsize= {individual ? '0.9rem' : '11px'} width='100%' hide ={hide !== 'no'} style={{ minHeight: 50, overflow: 'hidden' }}>
            {
              detail.map((data, id) => {
                return (
                  <span key={id}>
                    {data}
                    <br/>
                    <br/>
                  </span>
                )
              })
            }
            </BottomTitle>
            { !individual &&
            <Link to={`/case/${id}`}>
               <LinkText>
                Read full case study <span>&gt;</span>
              </LinkText>
            </Link>
            }
             { individual &&
             <IndividualLogoContainer >
              <img src={require(`../../assets/svgs/logo/${logo}`)} alt={title} style={{ maxWidth: '170px', height: '100%' }}/>
            </IndividualLogoContainer>}
        </RightChild>
      </Box>
    </CardBox>
    </Draggable>
  )
}

export default Card
