import React from 'react'

// styles
import { MarginContainer } from '../../assets/styles/Global.styled'
// components
import Banner from '../../components/Banner/Banner'
import Button from '../../components/Button/Button'
import Player from '../../components/Player/Player'
import { usePopupButton } from '../../hooks/usePopupButton'
import CaseStudy from './Section/CaseStudy'
import Company from './Section/Company'
import Confident from './Section/Confident'
import Journey from './Section/Journey'
import Pay from './Section/Pay'
import Reviews from './Section/Reviews'
import Schedule from './Section/Schedule'

const Home = ({ innerref }) => {
  const { popupModal, setOpen } = usePopupButton()
  return (
    <>
      <Banner />
      <MarginContainer>
        <Player radius="23px" imgWidth="20%" />

        <div style={{ marginTop: '40px', paddingBottom: '50px' }}>
          <Button
            text="Book a Call"
            mode="smalldark"
            clickEvent={() => setOpen(true)}
          />
        </div>
        {popupModal}
      </MarginContainer>

      <CaseStudy innerref={innerref} />
      <Company />
      <Confident />
      <Journey />
      <Pay />
      <Reviews />
      <Schedule />
    </>
  )
}

export default Home
