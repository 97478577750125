import React from 'react'

import CASE from '../../../constants/Case'

import { CenterText, Child, Slider, Container, Heading } from '../Home.styled'

import Carousel from '../../../components/Carousel/Carousel'
import Card from '../../../components/Card/Card'
import Button from '../../../components/Button/Button'
import { usePopupButton } from '../../../hooks/usePopupButton'

const CaseStudy = ({ innerref }) => {
  const { setOpen, popupModal } = usePopupButton()
  return (
    <Container heightXL='600px' heightXS='500px'>
      <Child>
        <Heading ref={innerref}>
          Case Study
        </Heading>
      </Child>
      <Child>
        <CenterText>
          No two brands are alike.<br />
          Each poses its own unique set of problems.<br />
          Check out how we have tackled them over the years.
        </CenterText>
      </Child>
      <Slider>
        <Carousel maxVisibility={3} >
          {CASE.map((details, id) => (
            <Card key={id} details={details} />
          ))}
        </Carousel>
      </Slider>
      <Child>
        <Button mode='light' text='BOOK A CALL' clickEvent={() => setOpen(true)} />
        {popupModal}
      </Child>
    </Container>
  )
}

export default CaseStudy
