import React from 'react'
import { MarginContainer } from '../../../assets/styles/Global.styled'
import Detail from '../../../components/Timeline/Detail'
import TimeLine from '../../../components/Timeline/TimeLine'
import { TextSpan, Title } from '../Home.styled'

import PHASE from '../../../constants/Phase'

const Journey = () => {
  return (
    <MarginContainer style={{ gap: '0px', padding: '5px 0 30px 0', marginTop: '2.5rem' }}>
        <Title dark={true} bold={true} style={{ fontSize: 'clamp(4rem, -2rem + 19.2vw, 8.8rem)' }}>
            Journey
        </Title>
        <TextSpan dark={true} style={{ marginBottom: '30px', fontWeight: '600', marginTop: '5px' }}>
            The system that was born out of 3+ years of experience.
        </TextSpan>
        <TimeLine dateData = {PHASE}>
          {PHASE.map((data, id) =>
              <Detail
              key ={id}
              data = {data}
              textPos = "left"
              />
          )}
        </TimeLine>
    </MarginContainer>
  )
}

export default Journey
