import styled from 'styled-components'

import '../../assets/fonts/index.css'
import '../../assets/fonts/MYRIADPRO-REGULAR.woff'

export const Phase = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.textPos === 'right' ? 'flex-end' : 'flex-start'};
    text-align-last: ${(props) => props.textPos};

    @media (max-width: 1170px) {
        align-items: flex-start;
    }
`

export const Heading = styled.h4`
    font-size: 1.4rem !important;
    letter-spacing : 0.27px;
    font-weight: 800;
`
export const Paragraph = styled.span`
    color : #000;
    font-size: 0.93rem;
    margin-top: 15px;
    font-weight: 500;
`
