import React from 'react'

import { Descriptions, DescriptionItem, DescriptionContainer, Absolute, TextContainer } from './casestudy.style'
import Arrow from '../../assets/svgs/bigarrow.svg'

// components
import CaseDescription from './CaseDescription'

const BeweegPot = () => {
  return (
    <>
      <TextContainer>
            <h2 style={{ fontSize: '38px', fontWeight: '500', padding: '20px 0' }}>
                De Beweegpot was an offline business and was making
                all of its revenue through other streams.
            </h2>

            <p style={{ color: '#000', fontSize: '1.25rem', fontWeight: '500' }}>
              The owners of the business were skeptical about getting results through Facebook ads.
              <br />
              <br />
              Most of the time, all agencies do is to run a test, run another test, and for safety`s sake, run 10 more tests.
              <br />
              <br />
              But there is absolutely a way to achieve the results AND run tests at the same time.
              <br />
              <br />
              The Business was running all organically i.e. NO PAID ADS.
            </p>

        </TextContainer>
        <CaseDescription/>
        {/* <TextContainer>
            <h2 style={{ fontSize: '41px', fontWeight: '500', padding: '20px 0' }}>
                We were successful <br/>
                in making the online presence <br/>
                of Beweeg Pot with the ROAS of 3.23.
            </h2>

        </TextContainer>

        <SampleAds dark={true} imgs = {['ad1.jpg', 'ad2.jpg', 'ad3.jpg']}/>

         <Descriptions>
          <DescriptionContainer>
              <DescriptionItem bold={true}>
                  Current Scenario
              </DescriptionItem>
              <DescriptionItem>
                <p>We continue to follow the above process on Little Triangle and it is now maintaining a ROAS of 3.5.</p>
                <p>We will continue to test other variables and have backups as we aim for a higher ROAS.</p>
              </DescriptionItem>
          </DescriptionContainer>
        </Descriptions> */}

        <Descriptions style={{ position: 'relative', marginBottom: '-20px' }}>
          <DescriptionContainer>
              <DescriptionItem bold={true} style={{ zIndex: 10, marginTop: '40px' }}>
                  Opportunities <br /> Ahead
              </DescriptionItem>
              <DescriptionItem>
                <p>Now that we have a consistent ROAS value of 3, we are now aiming at a ROAS of 4-5.</p>
                <p>The next goal would be to increase the order value through upselling and making product bundles. </p>
                <p>We continue to test creatives, offers, and audiences to further single out the combination that works. </p>
                <p>Now that you know how we work, you might have further questions. </p>
              </DescriptionItem>
          </DescriptionContainer>
          <Absolute>
            <img src={Arrow} alt="" style={{ height: '100%', maxWidth: '100%' }}/>
          </Absolute>
        </Descriptions>

    </>
  )
}

export default BeweegPot
