const REVIEWS = [
  {
    icon: 'review1.jpg',
    name: 'Mert',
    position: 'eCommercee & Digital Marketing Executive',
    star: 4.5,
    message: 'Great Agency. Can think for themselves and come up with great suggestions. would hire again.'
  },
  {
    icon: 'review2.jpg',
    name: 'Jose',
    position: 'Entrepreneur',
    star: 5,
    message: 'Social Ambience did a fantastic job for us, they over-delivered with consistency, high performance, and great communication. We work with them for multiple projects, and they were always great, we will definitely work with them in future projects.'
  },
  {
    icon: 'review3.jpg',
    name: 'Babar',
    position: 'Cinematographer',
    star: 5,
    message: 'It was really great working with Social Ambience. They understand the job so well and also give creative input to work. They are responsive, skilled, and cooperative. I really loved working with them and would love to work with them again in the near future.'
  }
]

export default REVIEWS
