const CONFIDENT = [
  {
    logo: 'confident1.svg',
    title: [
      'No Ad',
      'Failure'
    ],
    details: 'We have built a failure proof system of testing, optimizing and  scaling the campaigns with all the experience from the last three years spending over 10 million dollars.'
  },
  {
    logo: 'confident2.svg',
    title: [
      'No Ad',
      'Fatigue'
    ],
    details: 'By blending all our ad packages with the creatives so we always have content in hand to test!'
  },
  {
    logo: 'confident3.svg',
    title: [
      'Money Back',
      'Guarantee'
    ],
    details: 'We are so confident about our abilities that if you don’t see your number go up, you’ll get your money back.'
  }
]
export default CONFIDENT
