import React from 'react'

import { ImageContainer, RatioContainer, Image } from './Player.styled'
import YouTube, { YouTubeProps } from 'react-youtube';
import YoutubeLogo from '../../assets/svgs/youtube.svg'

const Player = ({ radius, imgWidth }) => {
  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
  const opts = {
    height: '390',
    width: '100',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return (
    <RatioContainer radius={radius}>
      <ImageContainer>
      <YouTube videoId="tQHoW58VuvM"  onReady={onPlayerReady} />
      </ImageContainer>
    </RatioContainer>
  )
}

export default Player
