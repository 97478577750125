import styled from 'styled-components'

export const Column = styled.div`
    width : 65%;
    margin-left: 17.5%;
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    gap: 1.2rem;
    font-size : 1.1rem;
    font-weight: 500;
`
export const ImageContainer = styled.div`
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
`

export const Inner = styled.div`
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    text-align: center;
`
export const Policies = styled.div`
    font-size : ${(props) => props.dark ? ' 1.388rem' : '1rem'};
    color : ${(props) => props.dark ? ' #792B7C' : '#000'};
    display: flex;
    flex-direction : column;
    gap: 15px;
    margin-top: 2px;
`
