import React from 'react'
import { Descriptions, DescriptionItem, DescriptionContainer, ImageContainer, BeforeAfter, Row } from './casestudy.style'

import IOS14 from '../../assets/svgs/ios14.svg'
import Chart from '../../components/Chart/Chart'
import DateText from '../../components/DateText/DateText'
import Table from '../../components/Table/Table'

const CaseDescription = () => {
  return (
    <>
    <Descriptions dark={true}>
            <DescriptionContainer>
                <DescriptionItem bold={true}>
                    Before We Started
                </DescriptionItem>
                <DescriptionItem>
                    <DateText date='Apr 1- Apr 30, 2021' compare ='compared to Mar 2-Mar 31, 2021'/>
                    <Row style={{ marginTop: '-8px' }}>
                        <Chart sales='0.00' salesPercent='-' arrowUp ={false} allSales={[]} timeline='bweeg2.svg' width='392px' height='265px' minWidth='30%' minHeight='400px'/>
                    </Row>
                </DescriptionItem>
            </DescriptionContainer>
        </Descriptions>

        <Descriptions>
            <DescriptionContainer>
                <DescriptionItem bold={true}>
                    What We Did
                </DescriptionItem>
                <DescriptionItem>
                    <p>We tested THREE types audiences at a time: Broad audiences, Stack Similar Big Brand Interests, and Lookalike audiences (Customer list priority).</p>
                    <p>We also tested new creatives that we made along with an offer of 20% discount.</p>
                    <p>We started testing the offer to those audiences which yielded better results.</p>
                    <p>After doing that we were immediately greeted with 18 purchases with a ROAS of 1.64 during the first week!</p>
                    <p>Considering this was their first stint at paid ads, the results were incredible.</p>
                </DescriptionItem>
            </DescriptionContainer>
        </Descriptions>

        <BeforeAfter>
            <Table text='After' width= '917px' height= '361px' img='beweegtable.svg' top='-3%'/>
            <DateText date='Sep 21-Dec 21, 2021' compare ='compared to Jun 21-Sep 20, 2021'/>
            <Row style={{ marginTop: '-8px' }}>
              <Chart sales='15,911.97' salesPercent='40%' arrowUp ={true} allSales={[['Online Store', '15,911.97', '45%']]} timeline='bweeg1.svg' width='354px' height='322px' minWidth='30%' minHeight='520px'/>
            </Row>
        </BeforeAfter>

        <Descriptions>
            <DescriptionContainer>
                <DescriptionItem bold={true}>
                    Optimizing and Maintaining <br />
                    The Campaign and iOS 14
                </DescriptionItem>
                <DescriptionItem>
                    <p>The arrival of the dreaded iOS 14 happened and we were left blind when it came to analyzing data.</p>
                    <p>For a week we ran ads hoping Facebook would feed us reliable data.</p>
                    <p>But as we all know, that didn<span>&apos;</span>t happen.</p>
                    <p>We desperately wanted to scale this business but with unreliable data, it was impossible.</p>
                </DescriptionItem>
            </DescriptionContainer>
        </Descriptions>

        <Descriptions dark={true} >
            <DescriptionContainer>
                <DescriptionItem bold={true}>
                    <span>How We Fought Back <br /> Against iOS 14</span>
                    <ImageContainer>
                        <img src={IOS14} alt="ios14" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
                    </ImageContainer>
                </DescriptionItem>
                <DescriptionItem>
                    <p>After waiting for Facebook proved to be a waste of time, we realized we had a trick up our sleeve - Urchin Tracking Module (UTM).</p>
                    <p>With UTM, we could go to the backend of either Google Analytics or Shopify to get the actual data.</p>
                    <p>When we found out that this little trick actually worked, we encoded it to every ad we had.</p>
                    <p>This allowed us to know which ads and audiences garnered better results so we could optimize the ads based on this data.</p>
                    <p>After this, our ad campaign started to come into equilibrium and even went up to a ROAS of 2.</p>
                    <p>Now that we had a few winning audiences and the ads, this was a perfect time to scale.</p>
                    <p>The scaling process was a success and we are currently looking at an average ROAS of 3.</p>
                </DescriptionItem>
            </DescriptionContainer>
        </Descriptions>
    </>
  )
}

export default CaseDescription
