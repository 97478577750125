import React, { useState } from 'react'

// styles
import { Nav, Navigation, LogoContainer, SideNav, SideMenu, Icon, ImgLogo } from './Navbar.style'

// images
import Logo from '../../assets/svgs/logo.svg'
import Links from './Links'

import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Link } from 'react-router-dom'

const Navbar = ({ caseRef }) => {
  const [isToggle, setIsToggle] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Nav>
        <LogoContainer>
          <Link to='/'>
            <ImgLogo src={Logo} alt="logo"/>
          </Link>
        </LogoContainer>
        <Navigation>
           <Links textColor='light' caseRef={caseRef}/>
        </Navigation>
        <SideNav>
          <Icon
          onMouseEnter={() => setIsHovered(!isHovered)}
          onMouseLeave={() => setIsHovered(!isHovered)}
          onClick={() => setIsToggle(!isToggle)} style={{ marginRight: '40px', color: '#fff' }}
          >

            { isHovered && <MenuOpenOutlinedIcon style={{ fontSize: '2rem' }}/>}
            {!isHovered && <MenuOutlinedIcon style={{ fontSize: '2rem' }}/>}

          </Icon>

            <SideMenu toggle={isToggle}>
               <Icon><CloseOutlinedIcon onClick={() => setIsToggle(false)}/></Icon>
               <Links textColor='dark'/>
            </SideMenu>

        </SideNav>
    </Nav>
  )
}

export default Navbar
