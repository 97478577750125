import React from 'react'
import { AdsContainer, Advertisement, AdImage, MiniTitle } from './casestudy.style'

const SampleAds = ({ dark, imgs, mb }) => {
  return (
    <AdsContainer dark={dark}>
        <MiniTitle dark={dark} style={{ marginBottom: mb }}>
            Sample Ads We Created
        </MiniTitle>
        <Advertisement>
            {
                imgs.map((item, id) =>
                    <AdImage key={id}>
                        <img src={require(`../../assets/images/${item}`)} alt="" style={{ maxHeight: '100%', maxWidth: '100%' }}/>
                    </AdImage>
                )
            }
        </Advertisement>
    </AdsContainer>
  )
}

export default SampleAds
