import styled from 'styled-components'

export const MainContainer = styled.div`
    /* min-height: 80vh; */
    width: 100%;
    margin-bottom: 20px;
`
export const Header = styled.div`
    width: 100%;
    min-height: 195px;
    background-color: ${(props) => props.theme.colors.primary.purple};
`
export const InnerContainer = styled.div`
    width: 70%;
    min-height: 195px;
    margin-left: 17.5%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
    @media (max-width: 980px) {
        flex-direction: ${(props) => props.reverse && 'column-reverse'};
    }
    @media (max-width: 600px) {
        width: 90%;
        margin-left: 5%;
    }
`
export const LogoContainer = styled.div`
    height: 195px;
    width: 35%;
    @media (max-width: 1250px) {
        display: none;
    }
`
export const ItemLeft = styled.div`
    width: 60%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 0.85rem;
    margin-top: 40px;
    @media (max-width: 980px) {
        width: 100%;
    }
    
`
export const ItemRight = styled.div`
    width: 35%;
     @media (max-width: 980px) {
        width: 100%;
        margin-bottom: 40px;
    }
`
export const ImageContainer = styled.div`
    max-width: 472px;
    max-height: 330px;
    margin-top: 30px;
    @media (max-width: 600px) {
        width: 372px;
    }
    @media (max-width: 400px) {
        width: 250px;
    }
   
`
export const Title = styled.h1`
    font-size: clamp(3rem, 0.89rem + 6.752vw, 4.688rem);
    color: #fff;
    font-weight:  900;
    width: 60%;

    @media (max-width: 1250px) {
        width: 100%;
    }
`
export const Text = styled.h3`
    font-size: clamp(1.1rem, 0.375rem + 2vw, 1.5rem);
    font-weight: 700;
    text-align-last: start;
    line-height: 42px;

     @media (min-width: 1850px) {
        font-size: 2.2rem;
    }
`

export const Price = styled.span`
    color: ${(props) => props.theme.colors.primary.purple};
    font-weight: 900;
    font-size: clamp(1.5rem, 0.2rem + 3.2vw, 1.9rem);

`

export const Paragraph = styled.p`
    font-size: clamp(0.8rem, 0.4889rem + 0.7111vw, 1.2rem);
    @media (min-width: 1850px) {
        font-size: 1.45rem;
    }

`
