import React from 'react'

import { Header, InnerContainer, Title, LogoContainer } from '../../containers/About/about.style'

import Logo from '../../assets/svgs/aboutuslogo.svg'

const Heading = ({ title }) => {
  return (
    <Header>
        <InnerContainer>
          <Title>
          {title}
          </Title>
          <LogoContainer>
            <img src={Logo} alt="" style={{ height: '195px' }}/>
          </LogoContainer>
        </InnerContainer>
      </Header>
  )
}

export default Heading
