const SOCIAL_MEDIA = [
  {
    icon: 'facebook.svg',
    name: 'facebook',
    path: 'https://www.facebook.com/socialambienceinternational?mibextid=LQQJ4d'
  },
  {
    icon: 'instagram.svg',
    name: 'instagram',
    path: 'https://www.instagram.com/socialambience/?igshid=YmMyMTA2M2Y%3D'
  },
  {
    icon: 'twitter.svg',
    name: 'twitter',
    path: 'https://twitter.com/'
  },
  {
    icon: 'linkedin.svg',
    name: 'linkedin',
    path: 'https://www.linkedin.com/company/socialambience/'
  }

]
export default SOCIAL_MEDIA
