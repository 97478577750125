import styled from 'styled-components'

export const Wrapper = styled.footer`
    min-height: 135px;
    width: 100%;
    background-color: ${(props) => props.mode === 'white' ? '#fff' : props.theme.colors.primary.purple}
`
export const FooterContainer = styled.div`
    width: 65%;
    margin-left: 17.5%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    @media (max-width: 960px) {
        width : 90%;
        margin-left: 5%;
    
    }
    @media (max-width: 720px) {
        flex-direction: column-reverse;
        gap: 15px;
        justify-content: center;
    }
    @media (max-width: 560px) {
        width : 100%;
        margin-left: 0;
        justify-content:center;
        align-items: center;

    }
`
export const FooterLeft = styled.div`
    width : 50%;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    gap: 1rem;
    font-weight: 400;
    @media (max-width: 830px) {
        width : 70%;
    }
    @media (max-width: 720px) {
        width: 100%;
        justify-content: center;
    }
`

export const LinkContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
     @media (max-width: 992px) {
       margin:auto;
       padding:5px 0px;
    }
`
export const LinkText = styled.p`
   color: ${(props) => props.mode === 'white' ? props.theme.colors.primary.purple : '#fff'};
   font-size: clamp(0.6rem, 0.625rem + 0.88vw, 1.1rem);
`
export const Icons = styled.div`
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
`
export const FooterRight = styled.div`
    width : 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 135px;
    @media (max-width: 830px) {
       width : 50%;
       height: 100%;
    }
    @media (max-width: 720px) {
        width: 30%;
        height: 90px;
    }
`
