import React from 'react'

import FOR_LIST from '../../../constants/ForList'

// styles
import { Break, Container, InnerContainer, ListItem, Text, Title, Row, Element, Child, Contact } from '../Home.styled'

// components
import Button from '../../../components/Button/Button'
import Discovery from '../../../components/Card/Discovery'
// import DateTime from '../../../components/Card/DateTime'

import TextSvg from '../../../assets/svgs/banner/bigpresent.svg'
import { usePopupButton } from '../../../hooks/usePopupButton'

const Schedule = () => {
  const { setOpen, popupModal } = usePopupButton()
  return (
    <Container style={{ paddingBottom: '80px' }}>
        <div style={{ width: '80%' }}>
          <img src={TextSvg} alt="Big Present Seen" style={{ maxWidth: '100%', maxHeight: '100%' }}/>
        </div>
        <InnerContainer>
            <Text>
                Schedule a 20 Minute <Break/> No Obligation Discovery <Break/> Call With Us.
            </Text>
            <Title fontmax={'1.7rem'} fontmin={'0.95rem'} style={{ textAlign: 'left', fontWeight: '500', margin: '15px 0' }}>
                By the end, we’ll have figured out your needs   <Break/>
                and formulated a plan to take your business   <Break/>
                to the next level.
            </Title>
            <Text>
                Who this is for:
            </Text>
            <Row>
                <Element>
                    <ul>
                    {
                        FOR_LIST.map((data, id) => {
                          return (
                            <ListItem key={id}>{data}</ListItem>
                          )
                        })
                    }
                    </ul>
                </Element>
                <Contact>
                    <Discovery/>
                    {/* <DateTime/> */}
                </Contact>
            </Row>
            <Child>
                  <Button mode='light' text='BOOK A CALL' clickEvent={() => setOpen(true)}/>
                  {popupModal}
            </Child>
        </InnerContainer>
    </Container>
  )
}

export default Schedule
