const PHASE = [
  {
    logo: 'phase1.svg',
    phase: 'Phase 1',
    title: ' Research and Strategize',
    details: 'We dive into the brand opportunities and pain points for growth. We analyze every campaign, every product, and every strategy youve tested so far and prepare a custom plan to dominate your niche market.'
  },
  {
    logo: 'phase2.svg',
    phase: 'Phase 2',
    title: 'Everything in Place',
    details: 'Auditing the landing page and the ad account will be the first thing. Before launching anything, we will ensure the tracking, pixel, UTM, ad account setup, ad angles, and creatives are in place.'
  },
  {
    logo: 'phase3.svg',
    phase: 'Phase 3',
    title: 'Test and Optimize',
    details: 'We will evaluate each campaign from the past (if any) and sort out high potential campaigns to optimize. Otherwise, We will test everything which we planned earlier: creatives, ad angles, audiences, placement, and demographics, depending on the ad budget you have.'
  },
  {
    logo: 'phase4.svg',
    phase: 'Phase 4',
    title: 'Scale and Grow',
    details: 'By this phase, we’ll have analyzed what will work for your brand and what won’t. We allocate bigger budgets to the winning combinations, maximize your sales and give you a steady stream of revenue while you focus on scaling in the real world.'
  }

]

export default PHASE
