import styled from 'styled-components'

export const InnerContainer = styled.div`
    width: 70%;
    margin-left: 17.5%;
`
export const Title = styled.h3`
    font-size: 36px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary.purple};
`
