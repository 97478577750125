import styled from 'styled-components'
import { DarkGradientCSS, LightGradientCSS } from '../../assets/styles/Global.styled'

export const BannerContainer = styled.div`
    width: 100vw;
    min-height: 647px;
    position: relative;
`
export const Wrapper = styled.div`
    position: absolute;
    height: 647px;
    width: 100%;
    top: 0;
    left: 0;
`
export const BannerAbsolute = styled.div`
    position: absolute;
    width: 100;
    top: 25%;
    left: 20%;
    @media (max-width: 1350px) {
        top: 20%;
        left: 20%;
    }
    @media (max-width: 1200px) {
        top: 10%;
        left: 10%;
    }
    @media (max-width: 900px) {
        top: 5%;
        left: 10%;
    }
`
export const Title = styled.h1`
    font-size: clamp(2rem, 0.89rem + 6.752vw, 4.688rem);
    color: #fff;
    font-weight:  900;
    margin-top: 8px;
    margin-left: -2px;

    @media (max-width: 1350px) {
        margin-top: 0;
    }

     @media (max-width: 510px) {
       color: #000;
    }
   
`

export const CardContainer = styled.div`
    width: 60%;
    margin-left: 20%;
    margin-top: -20.7%;
    margin-bottom: 80px;

    @media (max-width: 510px) {
       margin-top: 0px;
    }
    @media (max-width: 410px) {
       width: 90%;
       margin-left: 5%;
       margin-top: 40px;
    }

    
`

export const Descriptions = styled.div`
    width: 100%;
    background-color: ${(props) => props.dark ? props.theme.colors.primary.purple : '#fff'};
    color: ${(props) => props.dark ? '#fff' : props.theme.colors.primary.purple};
`
export const DescriptionContainer = styled.div`
    width: 65%;
    margin-left: 17.5%;
    padding: 45px 0;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1200px) {
        width: 80%;
        margin-left: 10%;
    }
    @media (max-width: 720px) {
        gap: 2rem;
    }
`
export const DescriptionItem = styled.div`
    width: 50%;
    font-weight :${(props) => props.bold ? 700 : 500};
    font-size: ${(props) => props.bold ? '1.55rem' : '0.98rem'};
    text-align-last: left;
    display: flex;
    flex-direction: column;
    gap: 11px;
    ul{
        padding:0px 30px;
        list-style:square;
    }
    @media (min-width: 1300px){
        font-size: ${(props) => props.bold ? '2.05rem' : '1.07rem'};
    }
    @media (min-width: 1600px){
        font-size: ${(props) => props.bold ? '2.95rem' : '1.35rem'};
    }
    @media (max-width: 1100px) {
        width: 100%;
        margin-top: 8px;
        align-items: ${(props) => props.bold && 'center'};
    }

`
export const AdsContainer = styled(Descriptions)`
    min-height : 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 35px 0;
`
export const Advertisement = styled(DescriptionContainer)`
    display : flex;
    justify-content: space-between;
    gap: 1rem;
`
export const AdImage = styled.div`
    width : 32%;
    height: 464px;
    /* min-width: 273px; */
    justify-content: center;
    align-items : center;
    display: flex;
    @media (max-width: 1000px) {
        width: 45%;
    }

    @media (max-width: 600px) {
        width: 100%;    
    }
`

export const ImageContainer = styled.div`
    width : 161px;
    height: 140px;
    margin-top: 8px;
`
export const MiniTitle = styled.h2`
    font-size: 25px;
    color : ${(props) => props.dark ? '#fff' : '#792B7C'};
    font-weight: 800;
    text-align: center;

    @media (min-width : 1650px) {
        font-size: 1.8rem;
    }

      @media (max-width: 500px) {
        font-size: 14px;
    }
`

export const TextContainer = styled(DescriptionContainer)`
    flex-direction: column;
    color: purple;
    gap: 1.1rem;
    justify-content: center;
    text-align: center;
`
export const MailBtn = styled.button`
    width: 420px;
    min-height: 60px;
    padding : 15px 10px;
    color: #fff;
    border: none;
    border-radius : 25px;
    font-size: 18px;
    ${DarkGradientCSS};
    margin-top: 20px;
    box-shadow: 0 8px 6px -6px rgba(233,139,211,0.85);
    cursor: pointer;

    &:hover{
        box-shadow: 0 8px 6px -6px #D4CDFE;
        ${LightGradientCSS};
        color: ${(props) => props.theme.colors.primary.purple};
    }

    @media (max-width: 650px) {
          width: 90%;
          margin-left: 5%;
    }
    @media (max-width: 340px) {
          font-size: 13px;
    }
     @media (max-width: 280px) {
          font-size: 11px;
          width : 98%;
          margin-left: 1%;
    }
`
export const Absolute = styled.div`
    position : absolute;
    min-width: 397px;
    left : 22%;
    top : 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    z-index : 9;
    @media (max-width: 1150px) {
        display: none;
    }
`
export const BeforeAfter = styled(Descriptions)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 35px 0;
    /* gap: 10px; */
    background-color: ${(props) => props.theme.colors.primary.purple};
    color : #fff;
    /* align-items: center; */
`
export const Row = styled.div`
    width : 100%;
    justify-content: ${(props) => props.content || 'center'};
    display: flex;
    @media (max-width: 1265px) {
        justify-content: center;
    }
     @media (max-width: 600px) {
        flex-direction: column;
    }
`
export const RowWrapper = styled.div`
    width: 100%;
    justify-content: center;
    align-items: center;
`
export const TableWrapper = styled.div`
    width:  ${(props) => props.wrapperWidth || '77%'};

    @media (max-width: 1265px) {
        width : 80%;
    }
`
export const Item = styled.div`
    width : 49%;
    display : flex;
    flex-direction : column;
    @media (max-width: 1265px) {
        width : 100%;
        display : flex;
        justify-content : center;
        align-items : center;
    }
`

export const RowContainer = styled.div`
    width : 64%;
    margin-left: 18%;
    justify-content: center;
    display: flex;
    @media (max-width: 1265px) {
        flex-direction: column;
    }
`

export const TableContainer = styled.div`
`
export const AbsoluteTile = styled.div`
    top : ${(props) => props.top};
    font-size: clamp(0.6rem, 0.2222rem + 1.7778vw, 2rem);

    @media (max-width: 650px) {
        top : ${(props) => props.top === '-10%' ? '-15%' : '-6%'};
    }

`

export const InnerContainer = styled.div`
    width: 80%;
    margin-left: 10%;
    margin-top: 80px;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: space-around;
`
