const FOR_LIST = [
  'Founders who feel stuck and want to scale their businesses.',
  "Founders who aren't experiencing a healthy monthly increase in revenue year-round.",
  'Founders who are tired of working with agencies that promise the world and deliver poor results.',
  'Founders that want proven marketing and sales strategies applied to their paid traffic funnel.',
  'Founders who are deeply seeking to become the industry leader and enter into a league of their own amongst their competition.',
  'You’re tired of marketing agencies that only do ad management & leave the burden of the creatives for you to deal with.',
  'You don’t have the time nor the patience to communicate with all the different agencies for Ad Copies, Creatives & Ad Management.',
  'The numbers show that you should be making a profile but you realize most of your profit goes towards acquiring more creatives.',
  'You want to focus on finding better opportunities for your business & not spend your time going from agency to agency.'
]

export default FOR_LIST
