import React from 'react'

// styles
import {
  Container,
  // GradientCurve,
  TextBox,
  Span,
  Overlay,
  BannerLeft,
  BannerRight,
  Slogan

} from './Banner.styled'

// images
// import Wave from '../../assets/svgs/curve.svg'
import Button from '../Button/Button'

// components

import { usePopupButton } from '../../hooks/usePopupButton'

const Banner = () => {
  const { setOpen, popupModal } = usePopupButton()
  return (
    <>
      <Container>
        <Overlay>
          <BannerLeft>
            <Slogan bold={true}>
              CREATIVES + AD MANAGEMENT <br />
              UNDER ONE ROOF = BETTER RESULTS

            </Slogan>
            <Slogan bold={true}> GUARANTEED!</Slogan>

            <div style={{ marginTop: '40px', paddingBottom: '50px' }}>
              <Button text="Book a Call" mode="smalldark" clickEvent={() => setOpen(true)} />
            </div>
            {popupModal}
          </BannerLeft>

          <BannerRight>
            <img src="/LandingImage.svg" width="100%" alt="homepage_image" />
          </BannerRight>
        </Overlay>
          <TextBox>
              <Span fontweight ='800' style={{ textTransform: 'uppercase' }}>How we help your business make 6-7 figures <br /> through paid ads at such low prices</Span>
              <br />
              <Span fontweight='600'>Explained in 5 minutes</Span>
          </TextBox>
      </Container>
    </>
  )
}

export default Banner
