const CASE = [
  {
    id: 1,
    logo: 'beweeg.svg',
    minititle: 'unique educational tool seller',
    title: [
      'Debeweeg Pot',
      '$0k to $15.9k'
    ],
    timePeriod: 'within 90 Days',
    fromTo: 'FROM $0 TO $15.9K WITHIN 90 DAYS.',
    miniInfo: 'How do we scale paid ads from square one to 15.9k within 90 days despite the iOS 14 update?',
    detail: [
      'De Beweegpot is a company based in the Netherlands that came up with a unique way for teachers to establish a good rapport with their students.',
      'The product is a simple jar that contains 56 “energizer” cards that are meant to be used in the class by teachers to make sure the students are fully concentrated.'
    ]
  },
  {
    id: 3,
    logo: 'wildlife.svg',
    minititle: 'KIDS EDUCATION',
    title: [
      'Wildlife Tree',
      '0.11 to 3.62'
    ],
    timePeriod: '',
    fromTo: 'FROM 0.11 RETURN ON AD SPEND TO 3.62 RETURN ON AD SPEND WITHIN 90 DAYS',
    miniInfo: 'We scaled the brand from a single sale to 183 in three months',
    detail: [
      'Wildlife Tree is focused on educating people to care for living things and to understand their environments. They aim to inspire children to become wildlife advocates through imagination and play. ',
      'Wildlife Tree has been a long-time animal education company, originally with live animal programs for kids.Over time they have shifted into an animal-focused retail company that supports zoos, conservation organizations as well as animal education organizations.'
    ]
  },
  {
    id: 2,
    logo: 'wildface.svg',
    minititle: 'Ecom CLothing Store',
    title: [
      'Wildface Clothing',
      '$1.3k to 140k+'
    ],
    timePeriod: 'Within 90 days',
    fromTo: 'From $45 to $4.5k per day in sales from Facebook ads.',
    miniInfo: 'This client of ours started at 1.3k per month when we took the ad account and now we’ve scaled to 140K+ per month in the three month time period…',
    detail: [
      'Wildface is an ecom store that focuses on selling winter clothing. When they first came to us, their ROAS was 1.06.',
      'Which was barely okay because they were more experienced in company management than in social media advertisement.',
      'Because it was difficult for Wildface to have a good day-to-day data analysis, they were giving more budget to the low-performing ads.',
      'They also were unaware of scaling their business through Facebook ads.'
    ]
  },
  {
    id: 1,
    logo: 'beweeg.svg',
    minititle: 'unique educational tool seller',
    title: [
      'Debeweeg Pot',
      '$0k to $15.9k'
    ],
    timePeriod: 'within 90 Days',
    fromTo: 'FROM $0 TO $15.9K WITHIN 90 DAYS.',
    miniInfo: 'How do we scale paid ads from square one to 15.9k within 90 days despite the iOS 14 update?',
    detail: [
      'De Beweegpot is a company based in the Netherlands that came up with a unique way for teachers to establish a good rapport with their students.',
      'The product is a simple jar that contains 56 “energizer” cards that are meant to be used in the class by teachers to make sure the students are fully concentrated.'
    ]
  },
  {
    id: 3,
    logo: 'wildlife.svg',
    minititle: 'KIDS EDUCATION',
    title: [
      'Wildlife Tree',
      '0.11 to 3.62'
    ],
    timePeriod: '',
    fromTo: 'FROM 0.11 RETURN ON AD SPEND TO 3.62 RETURN ON AD SPEND WITHIN 90 DAYS',
    miniInfo: 'We scaled the brand from a single sale to 183 in three months',
    detail: [
      'Wildlife Tree is focused on educating people to care for living things and to understand their environments. They aim to inspire children to become wildlife advocates through imagination and play. ',
      'Wildlife Tree has been a long-time animal education company, originally with live animal programs for kids.Over time they have shifted into an animal-focused retail company that supports zoos, conservation organizations as well as animal education organizations.'
    ]
  }

]
export default CASE
