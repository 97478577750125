import React from 'react'
import { MarginContainer } from '../../../assets/styles/Global.styled'
import { Companies, Individual, Title } from '../Home.styled'

const logos = [
  'wildface.svg',
  'bloc.svg',
  'notl.svg',
  'fastmask.svg',
  'livinglights.svg',
  'richesse.svg',
  'aveandyou.svg',
  'swisstonic.svg',
  'arildlinks.svg',
  'remarket.svg',
  'doubledouble.svg',
  'redbard.svg',
  'bestinbackyard.svg',
  'beweeg.svg'
]

const Company = () => {
  return (
    <MarginContainer style={{ marginTop: '2.8rem' }}>
        <Title dark={true} fontmax= '2.7rem' fontmin='1.25rem' bold={true}>
            Companies that trust us
        </Title>
        <Companies>
            {
                logos.map((logo, id) => {
                  return (
                    <Individual
                    key={id}
                    big = {!!((id === 5 || id === 8))}
                    right = {id === 3 || id === 6 || id === 9 || id === 13}
                    lastRow = { id > 9 }
                    >
                      <img
                      src={require(`../../../assets/svgs/logo/${logo}`)}
                      style ={{ maxWidth: (id === 5 || id === 8) ? 'auto' : '173px', maxHeight: '100%' }}
                      alt='logo'/>
                    </Individual>
                  )
                })
            }
        </Companies>
    </MarginContainer>
  )
}

export default Company
