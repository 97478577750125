import React from 'react'

// styles
import { InnerContainer, MainContainer, Text, Price, Paragraph, ItemLeft, ItemRight, ImageContainer } from './about.style'

import SideImage from '../../assets/svgs/aboutussideimg.svg'

import Heading from '../../components/Heading/Heading'

const About = () => {
  return (
    <MainContainer>
      <Heading title='About Us'/>
      <InnerContainer reverse={true} style={{ minHeight: '70vh' }}>
        <ItemLeft>
          <Text>
            After spending <Price>$1M</Price> on Facebook ads last year <br />
            & a total of over <Price>$4M</Price> on META Platforms <br />
            (Facebook and Instagram),
          </Text>
          <Paragraph>
            We have successfully created a steady cash flow for over <span style={{ color: '#792B7C', fontWeight: '800', fontSize: '0.97rem' }}> 25 </span> companies.
          </Paragraph>
          <Paragraph>
            To achieve those results, we<span>&apos;</span>ve built a team of advertising and content creative experts that allow us to develop appropriate strategies to put your brand on the map.
          </Paragraph>
          <Text style={{ marginTop: '20px' }}>
            The Result?
          </Text>
          <Paragraph>
            You start seeing incredible growth in as little as four weeks!
          </Paragraph>
          <Paragraph>
            Your now have a choice to choose between an upcoming agency like us or a popular international agency, but the truth is, the popular agency you might be going after <span style ={{ color: '#792B7C', fontWeight: '800' }}> is probably outsourcing the job to an upcoming agency like us!</span>
          </Paragraph>
        </ItemLeft>

        <ItemRight>
          <ImageContainer>
            <img src={SideImage} style={{ maxWidth: '100%', maxHeight: '100%' }}/>
          </ImageContainer>
        </ItemRight>

      </InnerContainer>

    </MainContainer>
  )
}

export default About
