import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import './timeline.css'

const TimeLine = ({ children, dateData }) => {
  return (
    <VerticalTimeline lineColor='#792B7C'>
        <VerticalTimelineElement style={{ display: 'none' }} />

        {
            children.map((child, id) => {
              return (
                <VerticalTimelineElement
                key ={id}
                date={<img className = 'logo-icon' src={require(`../../assets/svgs/phase/${dateData[id].logo}`)}/>}
                >
                    {child}
                </VerticalTimelineElement>
              )
            })
        }

    </VerticalTimeline>
  )
}

export default TimeLine
