import React from 'react'

// styles
import { Row } from '../../containers/Home/Home.styled'
import { Rounded, Image, DownArrow } from './Card.styled'

// image
import Logo from '../../assets/svgs/logodark.svg'

import ArrowIcon from '../../assets/svgs/downarrow.svg'
import { usePopupButton } from '../../hooks/usePopupButton'

// components

const Discovery = () => {
  const { calendyDisplay } = usePopupButton()
  return (
    <Rounded>
        <Row style={{ borderBottom: '1px solid purple', marginBottom: '5px' }}>
            <Image src={Logo} alt="logo"/>
        </Row>

         {calendyDisplay}

        <DownArrow>
            <img src={ArrowIcon} alt="" />
        </DownArrow>
    </Rounded>
  )
}

export default Discovery
