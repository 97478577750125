import React, { useEffect, useState } from 'react'

import { DisplayProvider } from './DisplayContext'

import { Container, CardContainer, NavContainer, Wrapper, Circle, RightBtn, LeftBtn } from './Carousel.styled'

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

const Carousel = ({ children, maxVisibility }) => {
  const count = React.Children.count(children)
  const [active, setActive] = useState(count <= 3 ? 1 : 0)

  const [position, setPosition] = useState({ x: 0, y: 0 })

  const arrowFont = {
    fontSize: '2.5rem'
  }

  const trackPos = (data) => {
    setPosition({ x: data.x, y: data.y })
  }

  useEffect(() => {
    if (position.x > 0) {
      if (active === 0) {
        setActive(count - 1)
        return
      }
      setActive((prev) => prev - 1)
    }
    if (position.x <= 0) {
      if (active === (count - 1)) {
        setActive(0)
        return
      }
      setActive((prev) => prev + 1)
    }
  }, [position])

  return (
    <Wrapper>
      <Container>
        {active > 0 && <LeftBtn onClick={() => setActive(i => i - 1)}><NavigateBeforeIcon style={arrowFont}/></LeftBtn>}
        {React.Children.map(children, (child, i) => (
          <CardContainer
          style={{
            '--active': i === active ? 1 : 0,
            '--offset': (active - i) / 4,
            // '--offset': (active - i) === -1 ? -0.35 : (active - i) === 1 ? 0.35 : 0,
            '--direction': Math.sign(active - i),
            '--abs-offset': Math.abs(active - i) / 4,
            opacity: Math.abs(active - i) >= maxVisibility ? '0' : '1',
            display: Math.abs(active - i) > maxVisibility ? 'none' : 'block'
          }}
          hide = {active === i }
          >
            <DisplayProvider value={{ styling: { visibility: i === active ? 'visible' : 'hidden' }, trackPos, active: (active === i || active - i === 1 || active - i === -1) }} >{child}</DisplayProvider>
          </CardContainer>
        ))}
        {active < count - 1 && <RightBtn onClick={() => setActive(i => i + 1)}><NavigateNextIcon style={arrowFont}/></RightBtn>}
      </Container>
      <NavContainer>
            {[...new Array(count)].map((_, id) => {
              return (
                <span key={id} onClick={() => setActive(id)}>
                  <Circle style={{ backgroundColor: id === active ? '#fff' : 'transparent' }}/>
                </span>
              )
            })}
      </NavContainer>
    </Wrapper>
  )
}

export default Carousel
