import React from 'react'
import { Heading, Paragraph, Phase } from './Timeline.styled'

const Detail = ({ data, textPos }) => {
  const { phase, title, details } = data
  return (
    <Phase textPos ={textPos}>
        <Heading>
            {phase}
        </Heading>
        <Heading>
            {title}
        </Heading>
        <Paragraph>
          {details}
        </Paragraph>
    </Phase>
  )
}

export default Detail
