import React, { useRef } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import THEME from './constants/Theme'

// styles
import GlobalStyles from './assets/styles/Global.styled'

// components
import Navbar from './components/Navbar/Navbar'
import Home from './containers/Home/Home'
import Footer from './components/Footer/Footer'
import About from './containers/About/About'
import CaseStudy from './containers/case-study/CaseStudy'
import Policy from './containers/Policy/Policy'
import ScrollToTop from './utils/ScrollToTop'
import CaseStudies from './containers/case-study/CaseStudies'
import Careers from './containers/Careers/Careers'

const App = () => {
  const caseRef = useRef(null)
  return (

    <ThemeProvider theme={THEME}>
      <GlobalStyles/>
      <BrowserRouter>
      <ScrollToTop/>
        <Navbar caseRef={caseRef}/>
        <Routes>
          <Route path='/' element={<Home innerref={caseRef} />}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/case' element={<CaseStudies/>}/>
          <Route path='/case/:caseid' element={<CaseStudy/>}/>
          <Route path='/policy' element={<Policy/>}/>
          <Route path='/careers' element={<Careers/>}/>
        </Routes>
        <Footer/>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
