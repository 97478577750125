import React from 'react'
import Button from '../../../components/Button/Button'
import { usePopupButton } from '../../../hooks/usePopupButton'
import { Container, Title, TextSpan, Child } from '../Home.styled'

const Pay = () => {
  const { setOpen, popupModal } = usePopupButton()
  return (
    <Container style={{ minHeight: 'auto', gap: '8px', padding: '38px 0 60px 0' }}>
        <Title fontmax= '2.8rem' fontmin='1.3rem' bold ={true}>
            Pay us 50% less <br /> of a typical agency fee
        </Title>
        <TextSpan>
            and well take care of all your ads
        </TextSpan>
         <Title fontmax= '2.4rem' fontmin='1.1rem' style={{ textTransform: 'uppercase', fontWeight: '500' }}>
            Creatives Included!
        </Title>
        <Child style={{ margin: '35px 0' }}>
            <Button
            clickEvent={() => setOpen(true)}
            text='BOOK A CALL'
            mode='light'/>
            {popupModal}
        </Child>
        <Title bold={true} fontmax= '2.2rem' fontmin='1.1rem' style={{ textTransform: 'uppercase', letterSpacing: '5px', fontWeight: '500' }}>
            Be the next success story
        </Title>
    </Container>
  )
}

export default Pay
