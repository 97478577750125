import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import './slider.css'
import { Keyboard, Scrollbar, Navigation, Pagination } from 'swiper'

const Slide = ({ children }) => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        centeredSlides={false}
        slidesPerGroupSkip={1}
        grabCursor={true}
        keyboard={{
          enabled: true
        }}
        scrollbar={true}
        navigation={false}
        pagination={{
          clickable: true
        }}
        modules={[Keyboard, Scrollbar, Navigation, Pagination]}
        className="mySwiper"
      >
        {
          children.map((data, id) => {
            return (
              <SwiperSlide key ={id} style={{ width: '100%' }}>
                {data}
              </SwiperSlide>
            )
          })
        }

      </Swiper>
    </>
  )
}

export default Slide
